import React from 'react';
import classNames from 'classnames';

import { Icon } from 'admin/components/common/Icon';

import css from './MessageContainer.scss';

type Props = {
	content: React.ReactNode | string;
	messageType: string;
	duration: number;
	close: () => void;
};

const MessageContainer = ({ content, messageType, duration, close }: Props) => (
	<div className={classNames(css.messageContent, css[messageType])} style={{ animationDuration: `${duration}s` }}>
		<Icon type={icon[messageType]} width={18} color="white" />
		<div className={css.body}>{content}</div>
		<button type="button" className={css.close} onClick={close}>
			<Icon type="close" width={18} color="white" />
		</button>
	</div>
);

MessageContainer.ERROR = 'error';
MessageContainer.SUCCESS = 'success';
MessageContainer.INFO = 'info';
MessageContainer.WARN = 'warn';
MessageContainer.CSS = css;

const icon = {
	[MessageContainer.SUCCESS]: 'done-all',
	[MessageContainer.ERROR]: 'info',
	[MessageContainer.WARN]: 'info',
	[MessageContainer.INFO]: 'info',
} as const;

export default MessageContainer;
