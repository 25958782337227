import { pick, set } from 'lodash';
import produce, { Draft } from 'immer';
import type { AsyncThunkActionReturnType } from 'types/redux-utils';
import type { StoryModel, DictionaryType, SpreadsheetRange } from 'types/story';
import { getStory, updatePublishedStory } from 'admin/actions';
import { getStorySpreadsheetIntegrations, StorySpreadsheetIntegration } from 'admin/actions/integrations';
import { storyEditorChange } from 'admin/actions/story/update-editable-story';
import type { UpdateLatestStoryAction } from 'admin/actions/story/update-latest-story';
import type { GetDictionaryAction } from 'admin/actions/story/get-story-dictionary';
import { StoryFacade } from 'utils/facades/story-facade';
import * as actionType from 'admin/constants/actions';

export interface IStoryEditorReducer {
	story: null | StoryModel;
	dictionary: null | DictionaryType;
	hideVoluntaryUpdates: boolean;
	spreadsheetVariables: SpreadsheetRange[];
	// keep in store integrated spreadsheet assets to be able to fetch them in preview to display values
	spreadsheetFiles: Pick<StorySpreadsheetIntegration['file'], 'hosting' | 'filepath'>[];
}

export const storyInitialState: IStoryEditorReducer = {
	story: null,
	dictionary: null,
	hideVoluntaryUpdates: false,
	spreadsheetVariables: [],
	spreadsheetFiles: [],
};

type ActionTypes =
	| {
			type: typeof actionType.GET_STORY_SPREADSHEET_INTEGRATIONS.FULFILLED;
			payload: AsyncThunkActionReturnType<typeof getStorySpreadsheetIntegrations>;
	  }
	| {
			type: typeof actionType.GET_STORY.FULFILLED;
			payload: AsyncThunkActionReturnType<typeof getStory>;
	  }
	| GetDictionaryAction
	| {
			type: typeof actionType.UPDATE_EDITABLE_STORY.UPDATE;
			payload: ReturnType<typeof storyEditorChange>['payload'];
	  }
	| UpdateLatestStoryAction
	| {
			type: typeof actionType.UPDATE_PUBLISHED_STORY.FULFILLED;
			payload: AsyncThunkActionReturnType<typeof updatePublishedStory>;
	  }
	| { type: typeof actionType.APPLY_STORY_VOLUNTARY_UPDATES.SUCCESS; payload: never }
	| { type: typeof actionType.STORY_EDITOR_UNMOUNT; payload: never };

function storyEditorReducer(state = storyInitialState, { type, payload }: ActionTypes): IStoryEditorReducer {
	if (type === actionType.GET_STORY.FULFILLED) {
		return {
			...state,
			story: payload.result!,
		};
	}

	if (type === actionType.GET_STORY_DICTIONARY.FULFILLED) {
		return {
			...state,
			dictionary: payload,
		};
	}

	if (type === actionType.UPDATE_EDITABLE_STORY.UPDATE) {
		const path = payload.path ? `story.${payload.path}` : 'story';
		return produce(state, draft => {
			set(draft, path, payload.data);
		});
	}

	if (type === actionType.UPDATE_LATEST_STORY.FULFILLED) {
		return produce(state, draft => {
			opCounterIncrement(draft);
			updateVersionTimestamp(StoryFacade.VERSIONS.latest, draft);
		});
	}

	if (type === actionType.UPDATE_PUBLISHED_STORY.FULFILLED) {
		return produce(state, draft => {
			opCounterIncrement(draft);
			updateVersionTimestamp(StoryFacade.VERSIONS.published, draft);
		});
	}

	if (type === actionType.APPLY_STORY_VOLUNTARY_UPDATES.SUCCESS) {
		return {
			...state,
			hideVoluntaryUpdates: true,
		};
	}

	if (type === actionType.GET_STORY_SPREADSHEET_INTEGRATIONS.FULFILLED) {
		return {
			...state,
			spreadsheetVariables: payload.result?.flatMap(integration => integration.ranges) ?? [],
			spreadsheetFiles: payload.result?.flatMap(item => pick(item.file, ['filepath', 'hosting'])) ?? [],
		};
	}

	if (type === actionType.STORY_EDITOR_UNMOUNT) {
		return { ...storyInitialState };
	}

	return state;
}

const updateVersionTimestamp = (
	version: typeof StoryFacade.VERSIONS.latest | typeof StoryFacade.VERSIONS.published,
	draft: Draft<IStoryEditorReducer>
) => {
	// local version update
	if (draft.story) draft.story.storyVersions[version].updatedAt = new Date().toISOString();
};

const opCounterIncrement = (draft: Draft<IStoryEditorReducer>) => {
	if (draft.story) draft.story.opCounter = (draft.story.opCounter ?? 0) + 1;
};

export default storyEditorReducer;
