import React from 'react';
import { message } from 'antd';

import MessageContainer from 'admin/components/common/MessageContainer';

const defaultDuration = parseInt(MessageContainer.CSS.duration, 10);

message.config({
	top: 0,
	duration: defaultDuration,
	maxCount: 1,
});

const key = 'antd-message';

const getProps = ({ type, content, duration = defaultDuration, onClose }) => ({
	content: (
		<MessageContainer content={content} messageType={type} duration={duration} close={() => toast.destroy()} />
	),
	duration,
	onClose,
	key,
});

export type ToastProps = { content: string; duration?: number; onClose?: (...args: any[]) => any };

export type ToastAction = (
	content: ToastProps['content'],
	duration?: ToastProps['duration'],
	onClose?: ToastProps['onClose']
) => void;

export type ToastType = 'success' | 'error' | 'info' | 'warn';

class Toast {
	success: ToastAction = (content, duration, onClose) =>
		message.success(getProps({ type: MessageContainer.SUCCESS, content, duration, onClose }));

	error: ToastAction = (content, duration, onClose) =>
		message.error(getProps({ type: MessageContainer.ERROR, content, duration, onClose }));

	info: ToastAction = (content, duration, onClose) =>
		message.info(getProps({ type: MessageContainer.INFO, content, duration, onClose }));

	warn: ToastAction = (content, duration, onClose) =>
		message.warning(getProps({ type: MessageContainer.WARN, content, duration, onClose }));
	destroy = (_key: string = key) => message.destroy(_key);
}
export const toast = new Toast();
