import React, { HTMLProps } from 'react';
import cn from 'classnames';
import css from './Label.scss';

type Props = {
	component?: React.ElementType | string;
	className?: string;
	children?: React.ReactNode;
} & HTMLProps<any>;

export const Label = ({ component: Component = 'label', ...props }: Props) => {
	return props.children ? <Component {...props} className={cn(css.pfLabel, props.className)} /> : null;
};
