/* eslint-disable no-bitwise */

export function rgbToHex(r: number, g: number, b: number) {
	return (b | (g << 8) | (r << 16) | (1 << 24)).toString(16).slice(1);
}

export function rgbToHsb(r: number, g: number, b: number) {
	const max = Math.max(r, g, b);
	const min = Math.min(r, g, b);
	const d = max - min;
	const s = max === 0 ? 0 : d / max;
	const v = max / 255;
	let h;

	switch (max) {
		case min:
			h = 0;
			break;
		case r:
			h = g - b + d * (g < b ? 6 : 0);
			h /= 6 * d;
			break;
		case g:
			h = b - r + d * 2;
			h /= 6 * d;
			break;
		case b:
			h = r - g + d * 4;
			h /= 6 * d;
			break;
		default:
			break;
	}

	return {
		h,
		s,
		b: v,
	};
}

export function hsbToHsl(h: number, s: number, b: number) {
	const _h = h;
	let _s = s * b;
	let _l = (2 - s) * b;

	_s /= _l <= 1 ? _l : 2 - _l;
	_l /= 2;

	if (Number.isNaN(_s)) {
		_s = 0;
	}

	return {
		h: +((_h * 100 * 360) / 100).toFixed(2),
		s: +(_s * 100).toFixed(2),
		l: +(_l * 100).toFixed(2),
	};
}

export function hexToRgb(hex: string) {
	const aRgbHex = hex.match(/.{1,2}/g);
	if (!aRgbHex) return { r: 0, g: 0, b: 0 };
	return { r: parseInt(aRgbHex[0], 16), g: parseInt(aRgbHex[1], 16), b: parseInt(aRgbHex[2], 16) };
}

export function hsbToRgb(h: number, s: number, brightness: number) {
	let r = 0;
	let g = 0;
	let b = 0;
	let i = 0;
	let f = 0;
	let p = 0;
	let q = 0;
	let t = 0;

	i = Math.floor(h * 6);
	f = h * 6 - i;
	p = brightness * (1 - s);
	q = brightness * (1 - f * s);
	t = brightness * (1 - (1 - f) * s);

	switch (i % 6) {
		case 0:
			r = brightness;
			g = t;
			b = p;
			break;
		case 1:
			r = q;
			g = brightness;
			b = p;
			break;
		case 2:
			r = p;
			g = brightness;
			b = t;
			break;
		case 3:
			r = p;
			g = q;
			b = brightness;
			break;
		case 4:
			r = t;
			g = p;
			b = brightness;
			break;
		case 5:
			r = brightness;
			g = p;
			b = q;
			break;
		default:
			break;
	}
	return {
		r: Math.floor(r * 255),
		g: Math.floor(g * 255),
		b: Math.floor(b * 255),
	};
}

export function hslToRgb(h: number, s: number, l: number) {
	const _s = s / 100;
	const _l = l / 100;
	const c = (1 - Math.abs(2 * _l - 1)) * _s;
	const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
	const m = l - c / 2;
	let r = 0;
	let g = 0;
	let b = 0;

	if (h >= 0 && h < 60) {
		r = c;
		g = x;
		b = 0;
	} else if (h >= 60 && h < 120) {
		r = x;
		g = c;
		b = 0;
	} else if (h >= 120 && h < 180) {
		r = 0;
		g = c;
		b = x;
	} else if (h >= 180 && h < 240) {
		r = 0;
		g = x;
		b = c;
	} else if (h >= 240 && h < 300) {
		r = x;
		g = 0;
		b = c;
	} else if (h >= 300 && h < 360) {
		r = c;
		g = 0;
		b = x;
	}

	return {
		r: Math.round((r + m) * 255),
		g: Math.round((g + m) * 255),
		b: Math.round((b + m) * 255),
	};
}

export function parseRgba(rgba: string, rgbaFallback = 'rgba(0, 0, 0, 1)') {
	const regex = /\d*\.?\d+/g;
	const match = rgba.match(regex) ?? rgbaFallback.match(regex)!;
	const [r, g, b, a = 1] = match.slice(0, 4).map(Number);
	return { r, g, b, a };
}

export function parseRgbaToAll(rgba: string) {
	const { r, g, b, a } = parseRgba(rgba);

	const hsb = rgbToHsb(r, g, b);
	const hsl = hsbToHsl(hsb.h, hsb.s, hsb.b);
	const hex = rgbToHex(r, g, b);

	return {
		hsb,
		rgb: { r, g, b },
		hsl,
		hex,
		opacity: a,
	};
}

export const getColorAlpha = (value = '') => {
	const rgbaValues = typeof value === 'string' ? value.match(/\d+/g) : null;

	return rgbaValues && rgbaValues.length === 4 ? rgbaValues[3] : '1';
};
