import { useCallback, useState } from 'react';

function useToggle(initialValue = false) {
	const [value, setValue] = useState(initialValue);
	const toggle = useCallback(() => setValue(v => !v), []);
	const set = useCallback((v: boolean) => setValue(v), []);

	return { value, toggle, set };
}

export default useToggle;
