import { Grid } from 'admin/components/common/Grid';

export const PROP_FIELD_TYPES = {
	text: 'text',
	number: 'number',
	color: 'color',
	select: 'select',
	iconSelect: 'iconSelect',
	checkbox: 'checkbox',
	toggle: 'toggle',
	radio: 'radio',
	textareaAutosize: 'textareaAutosize',
	media: 'media',
	slider: 'slider',
	date: 'date',
} as const;

type Column = Partial<{
	colSpan: string;
	justifyContent: string;
	alignItems: string;
	className: string;
}>;

type Gap = (typeof Grid.gap)[keyof typeof Grid.gap];

export const COLUMN = (column: Column = {}) => ({
	colSpan: '12',
	justifyContent: 'flex-start',
	alignItems: 'center',
	className: '',
	...column,
});

export const GRID_COLUMN_2 = ({
	col1 = {},
	col2 = {},
	gap = 'small',
}: {
	col1?: Column;
	col2?: Column;
	gap?: Gap | false;
} = {}) => ({
	disabled: false,
	columnsCount: '12' as const,
	columns: [COLUMN({ colSpan: '10', ...col1 }), COLUMN({ colSpan: '2', justifyContent: 'flex-end', ...col2 })],
	gap: gap || undefined,
});
