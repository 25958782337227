/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export default ({ color = '#FFF', ...props }: SVGAttributes<SVGElement> & { color?: string }) => (
	<svg width="24px" {...props} viewBox="0 0 24 24">
		<path
			d="M5 15h4a1 1 0 010 2H5a1 1 0 010-2zm0-4h14a1 1 0 010 2H5a1 1 0 010-2zm0-4h8a1 1 0 010 2H5a1 1 0 110-2z"
			fill={color}
		/>
	</svg>
);
