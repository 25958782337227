import type React from 'react';
import { FieldBBType, FIELD_DATA_ATTR, FIELD_EDITABLE_ATTR } from './constants';

export type TFieldProps = {
	className: string;
	type: FieldBBType;
	name: string;
	placeholder?: string;
	autoComplete?: 'off';
	[FIELD_DATA_ATTR]: string;
	[FIELD_EDITABLE_ATTR]?: string;
	value?: string | number;
	readOnly?: boolean;
	onDoubleClick?: (e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	onFocus?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	onClick?: (e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	style?: React.CSSProperties;
};

export enum DateFormat {
	DDMMYYYY = 'DDMMYYYY',
	MMDDYYYY = 'MMDDYYYY',
	YYYYMMDD = 'YYYYMMDD',
}

export enum DateDelimiter {
	'.' = '.',
	'/' = '/',
	'-' = '-',
}
