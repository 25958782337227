import React from 'react';
import FillPreview from 'admin/components/pages/Story/CardEditor/Inspector/PropField/FillPreview';
import css from './ColorPicker.scss';

type Props = {
	colors: string[];
	onChange: (color: string) => void;
};

const Swatches = (props: Props) => (
	<div className={css.swatch}>
		{props.colors.map(color => (
			<FillPreview
				key={`swatch-${color}`}
				className={css.swatchColor}
				value={color}
				onClick={() => props.onChange(color)}
			/>
		))}
	</div>
);

export default Swatches;
