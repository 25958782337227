import React, { useRef } from 'react';
import cn from 'classnames';
import css from './Slider.scss';

interface Props {
	value?: number;
	style?: React.CSSProperties;
	className?: string;
	onChange: (value: number) => void;
	onChangeEnd: () => void;
}

const Slider: React.FC<Props> = ({ value = 0.5, style = {}, className = '', onChange, onChangeEnd }) => {
	const sliderRef = useRef<HTMLDivElement>(null);

	const onDocumentMouseEvent = (event: MouseEvent) => {
		if (!sliderRef.current) return;
		const { left: sliderLeft, width: sliderW } = sliderRef.current.getBoundingClientRect();

		if (event.type === 'mousemove' || event.type === 'mouseup') {
			const targetX = Math.max(0, Math.min(event.pageX - sliderLeft, sliderW));
			const nextValue = parseFloat((targetX / sliderW).toFixed(2));
			onChange(nextValue);
		}

		if (event.type === 'mouseup') {
			onChangeEnd();
			document.removeEventListener('mousemove', onDocumentMouseEvent);
			document.removeEventListener('mouseup', onDocumentMouseEvent);
		}
	};

	const onSliderMouseEvent = (event: React.MouseEvent) => {
		event.preventDefault();
		if (!sliderRef.current) return;
		const { left: sliderLeft, width: sliderW } = sliderRef.current.getBoundingClientRect();

		document.addEventListener('mousemove', onDocumentMouseEvent);
		document.addEventListener('mouseup', onDocumentMouseEvent);

		const targetX = event.pageX - sliderLeft;
		const nextValue = parseFloat((targetX / sliderW).toFixed(2));
		onChange(nextValue);
	};

	const targetValue = Math.max(0, Math.min(value, 1));
	const handleStyle = { left: `calc(${targetValue * 100}%)` };

	return (
		<div style={style} className={cn(css.slider, className)} onMouseDown={onSliderMouseEvent}>
			<div ref={sliderRef} className={css.sliderInner}>
				<div style={handleStyle} className={css.sliderHandler}>
					<div className={css.sliderHandlerInner} />
				</div>
			</div>
		</div>
	);
};

export default Slider;
