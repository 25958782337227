import React, { ReactNode } from 'react';
import { findIndex } from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';

import type { CardData } from 'types/story';

import t from 'common/utils/translate';
import type { AdminReducerState } from 'admin/reducers';
import { FORM_MODEL } from 'admin/constants/common';

const fields = {
	name: 'name',
	cardType: 'cardType',
};

const connector = connect((state: AdminReducerState, ownProps: OwnProps) => {
	return {
		initialValues: {
			[fields.name]: '',
			[fields.cardType]:
				findIndex(ownProps.options, { value: ownProps.lastType }) >= 0
					? ownProps.lastType
					: ownProps.options[0].value,
		},
	};
});

type Fields = {
	name: string;
	cardType: CardData['type'];
};

type OwnProps = {
	children: (props: { submitting: boolean }) => ReactNode;
	onSubmit: (values: Fields) => void;
	lastType: string; // eslint-disable-line react/no-unused-prop-types
	// eslint-disable-next-line react/no-unused-prop-types
	options: { label: ReactNode; value: string; disabled?: boolean }[];
};
type PureProps = ConnectedProps<typeof connector> & OwnProps;
type FormProps = InjectedFormProps<Fields, PureProps>;
type Props = PureProps & FormProps;

const Form: React.FC<Props> = props => (
	<form onSubmit={props.handleSubmit(props.onSubmit)}>{props.children({ submitting: props.submitting })}</form>
);

const CreateCardModalForm = reduxForm<Fields, PureProps>({
	form: FORM_MODEL.CREATE_CARD, // <------ same form name
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true, // <---- unregister fields on unmount
	validate: (values, props) => {
		const errors = {};

		if (!values.name) {
			errors[fields.name] = t(`story.createCardModal.fields.${fields.name}.required`);
		}

		return errors;
	},
})(Form);

export { fields, Fields };

export default connector(CreateCardModalForm);
