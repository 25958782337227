import produce from 'immer';
import { pick } from 'lodash';
import {
	RESET_EDITABLE_CARD,
	SET_EDITABLE_EDITOR_ELEMENT,
	SET_EDITABLE_ELEMENT_MODE,
	SET_EDITOR_MODE,
	SET_EDITOR_SIZE,
	STORY_EDITOR_UNMOUNT,
	SET_TRAVERSE_TREE_COLLECTED_DATA,
} from 'admin/constants/actions';
import { DEFAULT_MEDIA_QUERY_SIZES, ELEMENT_EDIT_MODE } from 'common/constants';
import { EditorMode, SelectedBB } from 'types/story';
import type { SetElementEditModePayload } from 'admin/actions/card-editor/set-edit-mode';
import type { SetEditorSizePayload } from 'admin/actions/card-editor/set-editor-size';
import type { ResetEditableCardPayload } from 'admin/actions/card-editor/reset-edtiable-card';
import type { SetEditorModePayload } from 'admin/actions/card-editor/set-editor-mode';
import type { CollectedTreeData } from 'client/components/common/BuildingBlocks/utils/traverse-tree-data-collector';

type CardEditorReducerExtra = {
	editorMode: EditorMode;
	element: SelectedBB | {};
	size: Required<SetEditorSizePayload>;
	symbol: SetElementEditModePayload;
	traverseTreeData: CollectedTreeData | null;
};

type ActionTypes =
	| { type: typeof SET_EDITOR_MODE; payload: SetEditorModePayload }
	| { type: typeof SET_EDITABLE_EDITOR_ELEMENT; payload: SelectedBB }
	| { type: typeof SET_EDITABLE_ELEMENT_MODE; payload: SetElementEditModePayload }
	| { type: typeof SET_EDITOR_SIZE; payload: SetEditorSizePayload }
	| { type: typeof RESET_EDITABLE_CARD; payload: ResetEditableCardPayload }
	| { type: typeof SET_TRAVERSE_TREE_COLLECTED_DATA; payload: CollectedTreeData }
	| { type: typeof STORY_EDITOR_UNMOUNT; payload: never };

const initialState: CardEditorReducerExtra = {
	editorMode: EditorMode.DESIGN,
	// editable element in CardEditorCanvas(inside iframe DOM),
	// used to update Inspector and Layers components in CardEditor
	element: {},
	size: {
		width: DEFAULT_MEDIA_QUERY_SIZES.desktop.min,
		height: DEFAULT_MEDIA_QUERY_SIZES.desktop.min / DEFAULT_MEDIA_QUERY_SIZES.desktop.ratio,
		min: DEFAULT_MEDIA_QUERY_SIZES.desktop.min,
		max: DEFAULT_MEDIA_QUERY_SIZES.fullHD.min - 1,
		platform: 'desktop',
	},
	symbol: {
		editMode: ELEMENT_EDIT_MODE.INSTANCE,
		masterId: undefined, // root master symbol id
		instanceId: undefined, // instance for a which was changed editMode to MASTER
	},
	traverseTreeData: null,
};

function cardEditorExtraReducer(state = initialState, { type, payload }: ActionTypes): CardEditorReducerExtra {
	switch (type) {
		case SET_EDITOR_MODE:
			return { ...state, editorMode: payload.mode };
		case SET_EDITABLE_ELEMENT_MODE:
			return { ...state, symbol: payload };
		case SET_EDITOR_SIZE:
			return { ...state, size: { ...state.size, ...payload } };
		case RESET_EDITABLE_CARD:
			return { ...initialState, ...pick(state, payload.preserve) };
		case STORY_EDITOR_UNMOUNT:
			return { ...initialState };
		case SET_EDITABLE_EDITOR_ELEMENT:
			return produce(state, draftState => {
				draftState.element = payload;

				if (draftState.symbol.editMode === ELEMENT_EDIT_MODE.MASTER) {
					const [element1, element2] = Object.values(draftState.element);

					if (element2 !== undefined || element1.symbol?.instanceId !== draftState.symbol.instanceId) {
						draftState.symbol = initialState.symbol;
					}
				}
			});
		case SET_TRAVERSE_TREE_COLLECTED_DATA:
			return { ...state, traverseTreeData: payload };
		default:
			return state;
	}
}

export default cardEditorExtraReducer;
