import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import css from './Select.scss';

type Props = {
	innerRef?: {};
	options: {
		label?: string;
		value?: string;
	}[];
	isOpen?: boolean;
	selected?: string;
	className?: string;
	onSelect: (...args: any[]) => any;
};

// TODO: check selected type

export default class Select extends Component<Props> {
	static defaultProps = {
		selected: 0.5,
		className: '',
		innerRef: null,
		isOpen: false,
	};

	onOptionClick = e => {
		e.preventDefault();
		e.nativeEvent.stopImmediatePropagation();

		const { value } = e.currentTarget.dataset;

		this.props.onSelect(value);
	};

	render() {
		const { selected, innerRef, options, isOpen, className } = this.props;

		return (
			isOpen && (
				// @ts-expect-error ts-migrate FIXME
				<div ref={innerRef} className={classNames(css.select, className)}>
					{_.map(options, (o, index) => (
						<div
							onClick={this.onOptionClick}
							key={`opt-${index}`}
							data-value={o.value}
							className={css.option}
						>
							{o.value === selected && <div className={css.icon} />}
							<p>{o.label}</p>
						</div>
					))}
				</div>
			)
		);
	}
}
