/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export default ({ color = '#FFF', ...props }: SVGAttributes<SVGElement> & { color?: string }) => (
	<svg width="24px" {...props} viewBox="0 0 24 24">
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g opacity="0.499604725" transform="translate(8.480000, 5.891700)" fill={color}>
				<polygon points="3.508 0.214300487 0.164 6.67430049 3.68766467 6.67455049 3.687 10.9033005 6.896 4.54130049 3.50802395 4.54205049" />
			</g>
			<path
				d="M12.988024,2 L12.988024,9.43375 L17,9.43375 L11.1676647,21 L11.1676647,13.56625 L7,13.56625 L12.988024,2 Z M11.988,6.106 L8.644,12.566 L12.1676647,12.56625 L12.167,16.795 L15.376,10.433 L11.988024,10.43375 L11.988,6.106 Z"
				fill={color}
			/>
		</g>
	</svg>
);
