import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';
import type { AdminReducerState } from 'admin/reducers';
import { FORM_MODEL } from 'admin/constants/common';

export const selectEditableCardForm = (state: AdminReducerState) =>
	getFormValues(FORM_MODEL.EDIT_CARD)(state) as AdminReducerState['cardEditor']['present'];

const selectCardEditorReducer = (state: AdminReducerState) => state.cardEditor;

export const selectCardEditor = createSelector(selectCardEditorReducer, editor => editor.present);

export const selectEditableCard = createSelector(selectCardEditor, present => present.data);

export const selectEditableCardType = createSelector(selectCardEditor, present => present.data?.type);

export const selectEditableCardName = createSelector(selectCardEditor, present => present.data?.name);

export const selectEditableCardElements = createSelector(selectCardEditor, present => present.data?.elements);

export const selectEditableCardId = createSelector(selectEditableCard, card => card?._id);

export const selectEditableStoryElements = createSelector(selectCardEditor, present => present.storyElements);

export const selectEditableStorySettings = createSelector(selectCardEditor, present => present.storySettings);

export const selectEditableSymbols = createSelector(selectCardEditor, present => present.symbols);

export const selectEditableStateInfo = createSelector(selectCardEditor, present => present.state);

export const selectCurrentState = createSelector(selectEditableStateInfo, state => state.state);

export const selectCurrentPlatform = createSelector(selectCardEditor, present => present.currentMediaQuery);

export const selectAnswersMinMax = createSelector(
	[selectEditableCardId, selectEditableStorySettings],
	(cardId, settings) => (cardId === undefined ? cardId : settings.cards?.[cardId]?.answersMinMax)
);
