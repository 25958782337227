import { createSelector } from 'reselect';
import type { AdminReducerState } from 'admin/reducers';
import type { BBInheritanceParent, SelectedBBSingle } from 'types/story';

const selectCardEditorExtraReducer = (state: AdminReducerState) => state.cardEditorExtra;

const selectEditableElements = createSelector(selectCardEditorExtraReducer, reducer => reducer.element);

export const selectEditorMode = createSelector(selectCardEditorExtraReducer, reducer => reducer.editorMode);

export const selectEditableElement = createSelector(selectEditableElements, el => Object.values(el)[0]);

export const selectEditableElementId = createSelector(selectEditableElement, el => el._id);

export const selectEditableElementNodeId = createSelector(selectEditableElement, el => el.uiConfig.nodeProps.id);

export const selectEditableElementsCount = createSelector(selectEditableElements, el => Object.values(el).length);

export const selectEditableSymbolInfo = createSelector(selectCardEditorExtraReducer, reducer => reducer.symbol);

export const selectSymbolEditMode = createSelector(selectEditableSymbolInfo, symbol => symbol.editMode);

export const selectEditableElementPath = createSelector(selectEditableElement, el => el.path);

export const selectHasEditableElement = createSelector(selectEditableElement, el => el !== undefined);

// transform to string for accurate memoization
const selectEditableElementsIdStr = createSelector(selectEditableElements, elements => Object.keys(elements).join(' '));
export const selectEditableElementsId = createSelector(selectEditableElementsIdStr, ids => ids.split(' '));

// transform to string for accurate memoization
const joinBy = (e: SelectedBBSingle, by: keyof BBInheritanceParent) => e.inheritance?.parent.map(p => p[by]).join(' ');
const selectParentsIdStr = createSelector(selectEditableElement, el => joinBy(el, '_id'));
const selectParentsPathStr = createSelector(selectEditableElement, el => joinBy(el, 'path'));
const selectEditableElementParentsId = createSelector(selectParentsIdStr, ids => ids?.split(' '));
const selectEditableElementParentsPath = createSelector(selectParentsPathStr, path => path?.split(' '));

export const selectEditableElementParentsPathAndId = createSelector(
	[selectEditableElementParentsId, selectEditableElementParentsPath],
	(id, path) => id.map((_id, index) => ({ _id, path: path[index] }))
);

/* Why 'width' from 'user.preferences', but not from 'cardEditorExtra'?
 - Optimization. In 'cardEditorExtra.size.width' value updates more often. */
const selectEditorWidth = (state: AdminReducerState) => parseInt(state.user.preferences.editor.width || '0', 10);
const selectEditorMinWidth = (state: AdminReducerState) => state.cardEditorExtra.size.min || 0;
export const selectEditorScale = createSelector(
	[selectEditorWidth, selectEditorMinWidth],
	(width, minWidth) => width / minWidth
);

export const selectTraverseTreeData = createSelector(selectCardEditorExtraReducer, reducer => reducer.traverseTreeData);
