/* eslint-disable no-bitwise */
/* eslint-disable max-len */
import React, { Component } from 'react';
import cn from 'classnames';
import _ from 'lodash';
import { createPortal } from 'react-dom';
import { Label } from 'admin/components/pages/Story/CardEditor/Inspector/PropField/Label';

import transparencyTexture from 'assets/images/transparency-texture.jpg';

import {
	hsbToHsl,
	hsbToRgb,
	rgbToHsb,
	hslToRgb,
	parseRgbaToAll,
	rgbToHex,
	parseRgba,
	hexToRgb,
} from 'admin/utils/color-helpers';
import { Button } from 'admin/components/pages/Story/CardEditor/Inspector/PropField/Button';
import AdminError from 'admin/components/common/ErrorBoundary/AdminError';
import { Icon } from 'admin/components/common/Icon';

import Slider from './Slider';
import Select from './Select';
import Swatches from './Swatches';

import css from './ColorPicker.scss';

export const DEFAULT_COLOR = 'rgba(0, 0, 0, 1)';

const VIEW_MODE = {
	DEFAULT: 'DEFAULT',
	SIMPLE: 'SIMPLE',
} as const;

const autoSelect = (e: React.FocusEvent<HTMLInputElement>) => e.target.select();

type State = {
	rgba: string;
	hsb: {
		h: number;
		s: number;
		b: number;
	};
	rgb: {
		r: number;
		g: number;
		b: number;
	};
	hsl: {
		h: number;
		s: number;
		l: number;
	};
	hex: string;
	opacity: number;
	currentColorModel: 'RGB' | 'HSL' | 'HSB';
	isColorModelSelectOpen: boolean;
	isEditing: boolean;
};

type Props = {
	viewMode?: (typeof VIEW_MODE)[keyof typeof VIEW_MODE];
	rgba?: string;
	onChange?: (value: string) => void;
	reset?: boolean;
	swatches?: string[];
	theme?: {
		area?: string;
		root?: string;
		inputs?: string;
	};
};

export default class ColorPicker extends Component<Props, State> {
	static VIEW_MODE = VIEW_MODE;

	static COLOR_MODELS = {
		RGB: 'RGB',
		HSB: 'HSB',
		HSL: 'HSL',
	} as const;

	static defaultProps = {
		viewMode: VIEW_MODE.SIMPLE,
		rgba: DEFAULT_COLOR,
		onChange: _.noop,
		reset: false,
	};

	state: State = {
		rgba: this.props.rgba as string,
		...parseRgbaToAll(this.props.rgba as string),
		currentColorModel: ColorPicker.COLOR_MODELS.RGB,
		isColorModelSelectOpen: false,
		isEditing: false,
	};

	sbValuesAreaRef = React.createRef<HTMLDivElement>();

	colorPickerRef = React.createRef<HTMLDivElement>();

	colorModeSelectRef = React.createRef<HTMLDivElement>();

	static getDerivedStateFromProps(props: Props, state: State) {
		if (props.rgba !== state.rgba) {
			const rgba = props.rgba || DEFAULT_COLOR;
			return { rgba, ...parseRgbaToAll(rgba) };
		}
		return null;
	}

	get opacitySliderBgColor() {
		const { rgb } = this.state;

		return `
			linear-gradient(90deg, rgba(204, 31, 31, 0), #${rgbToHex(rgb.r, rgb.g, rgb.b)}),
			linear-gradient(180deg, rgba(39, 39, 39, 0.9), rgba(39, 39, 39, 0.9)),
			url("${transparencyTexture.replace(/^\.\//, '/')}")
		`;
	}

	get outputRGBA() {
		const { rgb } = this.state;
		return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${this.state.opacity})`;
	}

	onColorChange = (value: string) => {
		this.props.onChange?.(value);
	};

	onSwatchesChange = (rgba: string) => {
		this.setState(
			() => ({ ...parseRgbaToAll(rgba) }),
			() => {
				this.onColorChange(this.outputRGBA);
			}
		);
	};

	onDocumentMouseEvent = (e: MouseEvent) => {
		let pageX: number;
		let pageY: number;
		let targetX: number;
		let targetY: number;
		const { hsb } = this.state;
		const {
			left: sbValuesAreaLeft,
			top: sbValuesAreaTop,
			width: sbValuesAreaW,
			height: sbValuesAreaH,
		} = this.sbValuesAreaRef.current!.getBoundingClientRect();

		switch (e.type) {
			case 'mousemove':
			case 'mouseup':
				pageX = _.get(e, 'clientX');
				pageY = _.get(e, 'clientY');

				targetX = Math.max(0, Math.min(pageX - sbValuesAreaLeft, sbValuesAreaW));
				targetY = Math.max(0, Math.min(pageY - sbValuesAreaTop, sbValuesAreaH));

				this.setState(state => {
					const s = +(targetX / sbValuesAreaW).toFixed(2);
					const b = Number((1 - parseFloat((targetY / sbValuesAreaH).toFixed(2))).toFixed(2));
					const rgb = hsbToRgb(hsb.h, s, b);
					const hsl = hsbToHsl(hsb.h, s, b);

					return {
						isEditing: e.type !== 'mouseup',
						hsb: {
							...hsb,
							s,
							b: +b,
						},
						hsl,
						rgb,
						hex: rgbToHex(state.rgb.r, state.rgb.g, state.rgb.b),
					};
				});

				if (e.type === 'mouseup') {
					this.onColorChange(this.outputRGBA);

					document.removeEventListener('mousemove', this.onDocumentMouseEvent);
					document.removeEventListener('mouseup', this.onDocumentMouseEvent);
				}
				break;

			default:
				break;
		}
	};

	onHueValueChange = value => {
		this.setState(prevState => {
			const { hsb } = prevState;
			const hsl = hsbToHsl(value, hsb.s, hsb.b);
			const rgb = hsbToRgb(value, hsb.s, hsb.b);
			const hex = rgbToHex(rgb.r, rgb.g, rgb.b);

			return {
				isEditing: true,
				hsb: { ...hsb, h: value },
				hsl,
				rgb,
				hex,
			};
		});
	};

	onOpacityChange = (value: number) => {
		this.setState({ opacity: value, isEditing: true });
	};

	onSlidersChangeEnd = () => {
		this.onColorChange(this.outputRGBA);
		this.setState({ isEditing: false });
	};

	onSbValuesAreaMouseEvent = (e: React.MouseEvent<HTMLDivElement>) => {
		e.preventDefault();

		let pageX: number;
		let pageY: number;
		let targetX: number;
		let targetY: number;
		const { hsb } = this.state;
		const {
			left: sbValuesAreaLeft,
			top: sbValuesAreaTop,
			width: sbValuesAreaW,
			height: sbValuesAreaH,
		} = this.sbValuesAreaRef.current!.getBoundingClientRect();

		switch (e.type) {
			case 'mousedown':
				document.addEventListener('mousemove', this.onDocumentMouseEvent);
				document.addEventListener('mouseup', this.onDocumentMouseEvent);

				pageX = _.get(e, 'clientX');
				pageY = _.get(e, 'clientY');

				targetX = pageX - sbValuesAreaLeft;
				targetY = pageY - sbValuesAreaTop;

				this.setState(() => {
					const s = +(targetX / sbValuesAreaW).toFixed(2);
					const b = Number((1 - parseFloat((targetY / sbValuesAreaH).toFixed(2))).toFixed(2));
					const rgb = hsbToRgb(hsb.h, s, b);
					const hsl = hsbToHsl(hsb.h, s, b);

					return {
						isEditing: true,
						hsb: {
							...hsb,
							s,
							b: +b,
						},
						hsl,
						rgb,
						hex: rgbToHex(rgb.r, rgb.g, rgb.b),
					};
				});
				break;

			default:
				break;
		}
	};

	onColorPickerClick = e => {
		if (this.colorModeSelectRef.current && !this.colorModeSelectRef.current.contains(e.target)) {
			this.setState({ isColorModelSelectOpen: false }, () => {
				this.colorPickerRef.current!.removeEventListener('click', this.onColorPickerClick);
			});
		}
	};

	onColorModelClick = () => {
		this.setState({ isColorModelSelectOpen: true }, () => {
			this.colorPickerRef.current!.addEventListener('click', this.onColorPickerClick);
		});
	};

	onChangeColorModel = (model: 'RGB' | 'HSL' | 'HSB') => {
		this.setState({ isColorModelSelectOpen: false, currentColorModel: model }, () => {
			this.colorPickerRef.current!.removeEventListener('click', this.onColorPickerClick);
		});
	};

	getUpdatedState = (prevState, colorModel, value, fieldName) => {
		let { hsb, hsl, hex, rgb } = prevState;
		const val = +value.replace(/^0+/, '');

		if (_.isNaN(val)) {
			return prevState;
		}

		switch (colorModel) {
			case ColorPicker.COLOR_MODELS.RGB: {
				if (val > 255 || val < 0) {
					return prevState;
				}

				const targetRGB = {
					...prevState.rgb,
					[`${fieldName}`]: val,
				};

				hsb = rgbToHsb(targetRGB.r, targetRGB.g, targetRGB.b);
				hsl = hsbToHsl(hsb.h, hsb.s, hsb.b);
				hex = rgbToHex(targetRGB.r, targetRGB.g, targetRGB.b);

				return {
					hsb,
					hsl,
					hex,
					rgb: targetRGB,
				};
			}

			case ColorPicker.COLOR_MODELS.HSB: {
				let targetValue = 0;

				if ((fieldName === 'h' && val > 360) || ((fieldName === 's' || fieldName === 'b') && val > 100)) {
					return prevState;
				}

				if (fieldName === 'h') {
					targetValue = +(val / 360).toFixed(4);
				} else {
					targetValue = +(val / 100).toFixed(4);
				}

				const targetHSB = {
					...prevState.hsb,
					[`${fieldName}`]: targetValue,
				};

				rgb = hsbToRgb(targetHSB.h, targetHSB.s, targetHSB.b);
				hsl = hsbToHsl(targetHSB.h, targetHSB.s, targetHSB.b);
				hex = rgbToHex(rgb.r, rgb.g, rgb.b);

				return {
					hsb: targetHSB,
					hsl,
					hex,
					rgb,
				};
			}

			case ColorPicker.COLOR_MODELS.HSL: {
				const targetHSL = {
					...prevState.hsl,
					[`${fieldName}`]: val,
				};

				if ((fieldName === 'h' && val > 360) || ((fieldName === 's' || fieldName === 'l') && val > 100)) {
					return prevState;
				}

				rgb = hslToRgb(targetHSL.h, targetHSL.s, targetHSL.l);
				hsb = rgbToHsb(rgb.r, rgb.g, rgb.b);
				hex = rgbToHex(rgb.r, rgb.g, rgb.b);

				return {
					hsb,
					hsl: targetHSL,
					hex,
					rgb,
				};
			}

			default:
				return prevState;
		}
	};

	setStateFromHexValue = (value: string, callback: () => void) => {
		this.setState(
			prevState => {
				let { hsb, rgb, hsl } = prevState;

				if (value.length === 6) {
					rgb = hexToRgb(value);
					hsb = rgbToHsb(rgb.r, rgb.g, rgb.b);
					hsl = hsbToHsl(hsb.h, hsb.s, hsb.b);
				}

				return { rgb, hsl, hsb, hex: value.slice(0, 6) };
			},
			() => {
				callback?.();
			}
		);
	};

	onInputChange = e => {
		const {
			dataset: { colorModel },
			value,
		} = e.currentTarget;
		const name = e.currentTarget.getAttribute('name');
		const opacityPattern = /^[0-9]*$/gm;

		if (colorModel) {
			this.setState(
				prevState => this.getUpdatedState(prevState, colorModel, value, name.toLowerCase()),
				() => this.onColorChange(this.outputRGBA)
			);
		} else if (name === 'opacity' && opacityPattern.test(value)) {
			this.setState({ opacity: +value > 100 ? 1 : +value / 100 }, () => {
				this.onColorChange(this.outputRGBA);
			});
		} else if (name === 'hex') {
			this.setStateFromHexValue(value.replace('#', ''), () => {
				this.onColorChange(this.outputRGBA);
			});
		}
	};

	onGetColorFromScreenClick = async () => {
		try {
			// @ts-expect-error
			const eyeDropper = new window.EyeDropper();
			const { sRGBHex }: { sRGBHex: string } = await eyeDropper.open(); // sRGBHex can be rgb as well
			let hex: string = '';

			if (sRGBHex.startsWith('#')) {
				hex = sRGBHex.slice(1);
			} else {
				const rgb = parseRgba(sRGBHex);
				if (rgb) hex = rgbToHex(rgb.r, rgb.g, rgb.b);
			}

			if (!hex) {
				throw new AdminError('Failed to parse sRGBHex');
			}

			this.setStateFromHexValue(hex, () => {
				this.onColorChange(this.outputRGBA);
			});
		} catch (e) {
			console.info('color selection cancelled');
		}
	};

	onReset = () => {
		this.onColorChange('');
	};

	renderInputs = () => {
		const { viewMode } = this.props;
		const { opacity, hex, isColorModelSelectOpen, currentColorModel } = this.state;
		const isHSBModel = currentColorModel === ColorPicker.COLOR_MODELS.HSB;
		const model = _.values(_.get(this.state, `${currentColorModel.toLowerCase()}`));
		const isEyeDropper = 'EyeDropper' in window;
		const selectOptions = _.map(
			[ColorPicker.COLOR_MODELS.HSB, ColorPicker.COLOR_MODELS.HSL, ColorPicker.COLOR_MODELS.RGB],
			(o: string) => ({
				label: o,
				value: o,
			})
		);

		return (
			<div className={cn(css.controls, { [css.withPipette]: isEyeDropper })}>
				{isEyeDropper && (
					<button className={css.pipette} type="button" onClick={this.onGetColorFromScreenClick}>
						<Icon width={18} type="color-pick" />
					</button>
				)}
				<div className={css.inputs}>
					<div className={css.inputWrap}>
						<input
							name="hex"
							onChange={this.onInputChange}
							type="text"
							value={hex}
							onFocus={autoSelect}
							style={{ textTransform: 'uppercase' }}
						/>
					</div>
					{viewMode !== VIEW_MODE.SIMPLE && (
						<>
							<div className={css.inputWrap}>
								<input
									data-color-model={currentColorModel}
									// @ts-expect-error ts-migrate FIXME
									name={_.first(currentColorModel.split('')).toUpperCase()}
									onChange={this.onInputChange}
									type="text"
									value={
										isHSBModel
											? Math.round(model[0] * 360)
											: Number(Math.round(model[0]).toString())
									}
									onFocus={autoSelect}
								/>
							</div>
							<div className={css.inputWrap}>
								<input
									data-color-model={currentColorModel}
									name={_.get(currentColorModel.split(''), [1]).toUpperCase()}
									onChange={this.onInputChange}
									type="text"
									value={isHSBModel ? Math.round(model[1] * 100) : Math.round(model[1])}
									onFocus={autoSelect}
								/>
							</div>
							<div className={css.inputWrap}>
								<input
									data-color-model={currentColorModel}
									// @ts-expect-error ts-migrate FIXME
									name={_.last(currentColorModel.split('')).toUpperCase()}
									onChange={this.onInputChange}
									type="text"
									value={isHSBModel ? Math.round(model[2] * 100) : Math.round(model[2])}
									onFocus={autoSelect}
								/>
							</div>
						</>
					)}
					<div className={css.inputWrap}>
						<input
							onChange={this.onInputChange}
							name="opacity"
							type="text"
							value={Math.round(opacity * 100)}
							onFocus={autoSelect}
						/>
					</div>
				</div>
				<div className={css.labels}>
					<Label>Color HEX</Label>
					<div className={css.colorModel}>
						<Select
							className={css.select}
							innerRef={this.colorModeSelectRef}
							onSelect={this.onChangeColorModel}
							isOpen={isColorModelSelectOpen}
							options={selectOptions}
							selected={currentColorModel}
						/>
						<div onClick={this.onColorModelClick} className={css.colorModelInner}>
							<div className={css.icon} />
							<div className={css.labelWrap}>
								{/* @ts-expect-error ts-migrate FIXME */}
								<p>{_.first(currentColorModel.split('')).toUpperCase()}</p>
							</div>
							<div className={css.labelWrap}>
								<p>{_.get(currentColorModel.split(''), [1]).toUpperCase()}</p>
							</div>
							<div className={css.labelWrap}>
								{/* @ts-expect-error ts-migrate FIXME */}
								<p>{_.last(currentColorModel.split('')).toUpperCase()}</p>
							</div>
						</div>
					</div>
					<Label>Opacity</Label>
				</div>
			</div>
		);
	};

	render() {
		const { viewMode, theme } = this.props;
		const { hsb, opacity } = this.state;
		const { h, s, l } = hsbToHsl(hsb.h, 1, 1);
		const viewModeCtrlsCSS = {
			[ColorPicker.VIEW_MODE.DEFAULT]: css.default,
			[ColorPicker.VIEW_MODE.SIMPLE]: css.simple,
		};

		return (
			<div ref={this.colorPickerRef} className={cn(css.colorPicker, theme?.root, viewModeCtrlsCSS[viewMode!])}>
				<div
					style={{ backgroundColor: `hsl(${h}, ${s}%, ${l}%)` }}
					className={cn(css.sbValuesArea, theme?.area)}
					ref={this.sbValuesAreaRef}
					onMouseDown={this.onSbValuesAreaMouseEvent}
				>
					<div
						style={{
							top: `${(1 - hsb.b) * 100}%`,
							left: `${hsb.s * 100}%`,
						}}
						className={css.sbValuesHandler}
					>
						<div className={css.sbValuesHandlerInner} />
					</div>
				</div>
				{this.props.reset && (
					<Button preset="reset" onClick={this.onReset} className={css.btnReset}>
						reset color
					</Button>
				)}

				<div className={cn(css.bottom, theme?.inputs)}>
					<Slider
						onChange={this.onHueValueChange}
						onChangeEnd={this.onSlidersChangeEnd}
						value={hsb.h}
						className={css.hueValueSlider}
					/>
					<Slider
						style={{ backgroundImage: this.opacitySliderBgColor }}
						onChange={this.onOpacityChange}
						onChangeEnd={this.onSlidersChangeEnd}
						value={opacity}
						className={css.opacitySlider}
					/>
					{this.renderInputs()}
				</div>
				{!!this.props.swatches?.length && (
					<Swatches colors={this.props.swatches} onChange={this.onSwatchesChange} />
				)}
				{this.state.isEditing && createPortal(<div className={css.overlay} />, document.body)}
			</div>
		);
	}
}
