import _ from 'lodash';
import { SET_LOADING, STORY_EDITOR_SYNC, CARD_EDITOR_SYNC } from 'admin/constants/actions';
import { SYNC_STATUS } from 'admin/constants/common';

type LoadingState = {
	syncStoryStatus: (typeof SYNC_STATUS)[keyof typeof SYNC_STATUS];
	syncCardStatus: (typeof SYNC_STATUS)[keyof typeof SYNC_STATUS];
	busy: boolean;
};

type LoadingActions =
	| { type: typeof SET_LOADING; payload: { [key: string]: boolean } }
	| { type: (typeof STORY_EDITOR_SYNC)[keyof typeof STORY_EDITOR_SYNC]; payload: any }
	| { type: (typeof CARD_EDITOR_SYNC)[keyof typeof CARD_EDITOR_SYNC]; payload: any };

const initialState: LoadingState = {
	syncStoryStatus: SYNC_STATUS.UPDATED,
	syncCardStatus: SYNC_STATUS.UPDATED,
	busy: false,
};

/**
 * Global loading reducer.
 * Set loading for particular component or route.
 */
export default function loadingReducer(state = initialState, action: LoadingActions): LoadingState {
	const { type, payload } = action;

	switch (type) {
		// Loading for particular component or route
		case SET_LOADING:
			return {
				...state,
				...payload,
				busy: _.some(_.values(_.omit({ ...state, ...payload }, ['busy', 'syncStoryStatus', 'syncCardStatus']))),
			};

		case STORY_EDITOR_SYNC.DRAFT:
			return { ...state, syncStoryStatus: SYNC_STATUS.DRAFT };
		case STORY_EDITOR_SYNC.FAIL:
			return { ...state, syncStoryStatus: SYNC_STATUS.FAIL };
		case STORY_EDITOR_SYNC.SYNC:
			return { ...state, syncStoryStatus: SYNC_STATUS.SYNC };
		case STORY_EDITOR_SYNC.SUCCESS:
			return { ...state, syncStoryStatus: SYNC_STATUS.UPDATED };

		case CARD_EDITOR_SYNC.DRAFT:
			return { ...state, syncCardStatus: SYNC_STATUS.DRAFT };
		case CARD_EDITOR_SYNC.FAIL:
			return { ...state, syncCardStatus: SYNC_STATUS.FAIL };
		case CARD_EDITOR_SYNC.SYNC:
			return { ...state, syncCardStatus: SYNC_STATUS.SYNC };
		case CARD_EDITOR_SYNC.SUCCESS:
			return { ...state, syncCardStatus: SYNC_STATUS.UPDATED };

		default:
			return state;
	}
}
