import _ from 'lodash';
import type {
	BBModel,
	BBSymbolLink,
	CardData,
	StoryModel,
	StorySymbols,
	StoryVersionType,
	WithStateAndPlatform,
	BBStylesProp,
} from 'types/story';
import produce, { Draft } from 'immer';
import { appLog } from 'utils/helpers';
import facades from 'utils/facades/card-facades';
import { StoryFacade } from 'utils/facades/story-facade';
import { componentWalk } from 'utils/blocks/component-walk';
import { isSymbol, isSymbolLink } from 'utils/blocks/symbol';
import { CARD_DEFAULT_TEMPLATE_NAME, COMPONENT_STATES, COMPONENT_TYPE } from 'common/constants';
import { INTEGRATIONS_NAMES, IntegrationByName } from 'utils/facades/integrations-facade';

import type { AnimationData } from 'client/components/common/BuildingBlocks/animation';
import { ShareOgSource, ShareTarget } from 'client/components/common/BuildingBlocks/Share/types';

import { platformsConfig } from 'admin/utils/story-media-platforms-config';

const log = appLog.extend('utils:StoryMigrationAdapter');

type VoluntaryUpdate = {
	ver: string; // story version when changed was published
	timestamp: string;
	desc: string; // description of the update
	apply: () => StoryMigrationAdapter; // function to apply story update
	status: () => boolean; // is updated applied or not. (true: applied)
};

export type VoluntaryUpdateList = VoluntaryUpdate[];

const LS_SKIP_UPDATE = 'sc-update-skip';

/**
 * @info Migration currently in only supported for a latest version of story only.
 */
export class StoryMigrationAdapter {
	story: StoryModel;

	constructor({ story }: { story: StoryModel }) {
		this.story = story;
	}

	get voluntaryUpdateList(): VoluntaryUpdateList {
		return [
			{
				ver: '1.5.0',
				timestamp: '2021-08-17T14:00:00.000Z',
				desc:
					'Added "Full HD" platform.' +
					'\n • "Full HD" - 1920px a wider' +
					'\n • "Desktop" - 1280px a wider' +
					'\n • "Tablet" - 1279px - 768px' +
					'\n • "Mobile" - 767px and less',
				apply: () => {
					this.story = produce(this.story, draft => {
						const { mediaQuery } = draft.storyVersions[StoryFacade.VERSIONS.latest].data;
						mediaQuery.config = platformsConfig[mediaQuery.defaultPlatform];
					});
					return this;
				},
				status: () => !!this.story.storyVersions[StoryFacade.VERSIONS.latest].data.mediaQuery.config.fullHD,
			},
			{
				ver: '1.5.27',
				timestamp: '2021-10-25T14:00:00.000Z',
				desc:
					'Added "Mobile landscape" platform.' +
					'\n • "Mobile landscape" - 767px and less.' +
					'\n • "Mobile" - 478px and less.',
				apply: () => {
					this.story = produce(this.story, draft => {
						const { mediaQuery } = draft.storyVersions[StoryFacade.VERSIONS.latest].data;
						mediaQuery.config = platformsConfig[mediaQuery.defaultPlatform];
					});
					return this;
				},
				status: () =>
					!!this.story.storyVersions[StoryFacade.VERSIONS.latest].data.mediaQuery.config.mobileLandscape,
			},
		];
	}

	get storyVoluntaryUpdateList() {
		return this.voluntaryUpdateList.filter(o => !o.status());
	}

	skipUpdate(updates: VoluntaryUpdateList) {
		localStorage.setItem(
			LS_SKIP_UPDATE,
			JSON.stringify(Object.assign(StoryMigrationAdapter.lsSkipItem, { [this.story.id]: updates[0].ver }))
		);
	}

	isShowUpdate(updates: VoluntaryUpdateList) {
		return updates.length > 0 && StoryMigrationAdapter.lsSkipItem?.[this.story.id] !== updates[0].ver;
	}

	private static get lsSkipItem() {
		return JSON.parse(localStorage.getItem(LS_SKIP_UPDATE) ?? '{}');
	}

	/**
	 * From version 1.2.0 all story symbols were moved from "story.settings.symbols" to "story.data.symbols"
	 */
	private moveSymbols(draft: Draft<StoryVersionType>) {
		log('moveSymbols:START');

		const oldPath = 'settings.symbols';
		const oldSymbols = _.get(draft, oldPath) as StorySymbols | undefined;

		if (oldSymbols) {
			log('moveSymbols:ADAPT', draft.storyId);
			draft.data.symbols = { ...oldSymbols };

			_.unset(draft, oldPath);
		}

		log('moveSymbols:COMPLETE');

		return this;
	}

	/**
	 * From version 1.8.0 all story integrations moved to "story.settings.integrations"
	 * Old ones: "story.settings.ga", "story.settings.ga4", "story.settings.headScript", "story.settings.bodyScript"
	 * @private
	 */
	private moveStoryIntegrations(draft: Draft<StoryVersionType>) {
		type Path<T extends INTEGRATIONS_NAMES> = `${T}.${IntegrationByName<T>['fields'][number]}`;
		(
			[
				['ga', `${INTEGRATIONS_NAMES.GA}.gaUniversal`],
				['ga4', `${INTEGRATIONS_NAMES.GA}.ga4`],
				['headScript', `${INTEGRATIONS_NAMES.SCRIPT}.headScript`],
				['bodyScript', `${INTEGRATIONS_NAMES.SCRIPT}.bodyScript`],
			] satisfies [string, Path<INTEGRATIONS_NAMES>][]
		).forEach(([oldPath, newPath]) => {
			if (_.has(draft.settings, oldPath)) {
				log('moveStoryIntegrations:ADAPT', oldPath);
				// eslint-disable-next-line no-param-reassign
				if (!draft.settings.integrations) draft.settings.integrations = {};

				// set old value at new path
				_.set(draft.settings.integrations, newPath, _.get(draft.settings, oldPath));

				// remove old field
				_.unset(draft.settings, oldPath);
			}
		});
	}

	/**
	 * Card settings were changed many times but must always contain latest configurations
	 */
	private updateCardSettings(card: CardData) {
		log('updateCardSettings:START', card._id);

		if (!facades[card.type]) {
			log('updateCardSettings:COMPLETE');
			return this;
		}

		const template = facades[card.type].template[CARD_DEFAULT_TEMPLATE_NAME]({
			/* name and type are random, they are required to initialize default template.
			   only the settings are needed from the template. */
			type: 'INFO',
			name: '...',
		});

		// eslint-disable-next-line no-param-reassign
		card.settings = { ...(card?.settings || {}), ...template.editor.card.settings };

		log('updateCardSettings:COMPLETE', card._id);

		return this;
	}

	/**
	 * Loop through blocks and update theirs data
	 */
	private updateBuildingBlocks(elements: (BBModel | BBSymbolLink)[]) {
		log('updateBuildingBlocks:START');

		componentWalk(elements, function walk({ component, path, index }) {
			/* [UPDATE BUILDING BLOCK DATA] - Start */
			if (!component || isSymbolLink(component) || !component.uiConfig?.componentProps) return;

			animationDataUpdate(component);

			dataUnselectableUpdate(component);

			requireComponentOtherPropsUpdate(component);

			shareCardPropertyUpdate(component);

			replaceStyles(component);

			componentWalk(component.children, walk);
			/* [UPDATE BUILDING BLOCK DATA] - End */
		});

		log('updateBuildingBlocks:COMPLETE');

		return this;
	}

	/**
	 * Forced migration
	 */
	adapt() {
		const t0 = performance.now();
		const version = this.story.storyVersions[StoryFacade.VERSIONS.latest];
		const { appVersion } = this.story.storyVersions[StoryFacade.VERSIONS.latest].data;

		if (!version || (appVersion && appVersion === process.env.VERSION)) {
			return this.story;
		}

		log('adapt:START');

		// change symbol path
		this.moveSymbols(version);

		// change integrations path
		this.moveStoryIntegrations(version);

		// update global blocks
		this.updateBuildingBlocks(version.data.elements);

		// update symbol blocks
		const symbols: BBModel[] = [];
		Object.values(version.data.symbols || {}).forEach(symbol => {
			symbols.push(symbol.master);
			Object.values(symbol.instance).forEach(instance => {
				symbols.push(instance);
			});
		});
		this.updateBuildingBlocks(symbols);

		version.data?.steps?.forEach(step => {
			step.cards.forEach(card => {
				// Update
				this.updateCardSettings(card);

				// Update
				this.updateBuildingBlocks(card.elements);
			});
		});

		log('adapt:COMPLETE', `${(performance.now() - t0).toFixed(4)}ms`);

		return this.story;
	}
}

/**
 * This migration function is designed to replace deprecated boolean property `uiConfig.componentProps.shareCard`
 * with the appropriate new properties introduced in v1.27.0 to determine which link to share.
 *
 * @since v1.27.0
 */
function shareCardPropertyUpdate(draftComponent: BBModel) {
	if ('shareCard' in draftComponent.uiConfig.componentProps) {
		const oldValue = draftComponent.uiConfig.componentProps.shareCard as boolean;
		if (oldValue) {
			draftComponent.uiConfig.componentProps.shareTarget = ShareTarget.story;
			draftComponent.uiConfig.componentProps.shareOgSource = ShareOgSource.card;
		}
		delete draftComponent.uiConfig.componentProps.shareCard;
	}
}

/**
 * This migration function is designed to add missed required field to support very old templates/stories.
 * `uiConfig.componentProps.other` is required field for any building block, only symbol instance may not contain it.
 * Probably it become a required since `v1.1.18`, commit hash `0b3e6295`, but was first discovered in `v1.14.1`
 *
 * @since v1.14.2
 */
function requireComponentOtherPropsUpdate(draftComponent: BBModel) {
	if (!draftComponent.uiConfig.componentProps.other && !isSymbol(draftComponent)) {
		draftComponent.uiConfig.componentProps.other = {};
	}
}

/**
 * This migration function is designed to replace deprecated property `uiConfig.nodeProps.['data-unselectable']`
 * with the appropriate new property `uiConfig.editorProps.selectable` which is responsible for the ability to select
 * block in card editor.
 *
 * Old path: uiConfig.nodeProps.['data-unselectable']
 * New path: uiConfig.editorProps.selectable
 *
 * @since v1.13.0
 */
function dataUnselectableUpdate(draftComponent: BBModel) {
	const oldValue = draftComponent.uiConfig?.nodeProps?.['data-unselectable'];

	if (oldValue === undefined) {
		return;
	}

	log('dataUnselectableUpdate:ADAPT');
	draftComponent.uiConfig.editorProps.selectable = !oldValue;
	delete draftComponent.uiConfig.nodeProps['data-unselectable'];
}

/**
 * This migration function is designed to update deprecated `AnimationData` structure.
 * In `v1.5.1` was introduced new animation settings UI/UX and new features. AnimationData object was changed.
 *
 * @since v1.5.5
 */
function animationDataUpdate(component: BBModel) {
	const animationRoot = component.uiConfig?.componentProps?.animation?.[COMPONENT_STATES.DEFAULT];

	if (!animationRoot) return;

	Object.keys(animationRoot).forEach((platform, i, arr) => {
		const animationData: Draft<Record<string, AnimationData> | AnimationData> = animationRoot[platform];

		/*
			 Update animation:
			 Old format: animationData = AnimationData;
			 New format: animationData = Record<string, AnimationData>
		 */
		if (animationData.keyframes || animationData.trigger) {
			log('animationDataUpdate:ADAPT');

			if ((animationData.keyframes as any)?.initial && (animationData.keyframes as any)?.final) {
				animationRoot[platform] = updateAnimation(
					animationData as AnimationData,
					`${component.type} - ${component._id.slice(0, 4)}`
				);
			} else {
				delete animationRoot[platform];
			}
		}
	});
}

function updateAnimation(draft: Draft<AnimationData>, name: string) {
	const id = 'migration';

	return {
		[id]: {
			...draft,
			id,
			name,
			delay: '0',
			loop: 'false',
			reverse: 'false',
			keyframes: {
				'0': updateKeyframe(draft.keyframes.initial, { position: '0', name, id, ease: draft.ease }),
				'100': updateKeyframe(draft.keyframes.final, { position: '100', name, id, ease: draft.ease }),
			},
		},
	};
}

function updateKeyframe(
	keyframe: AnimationData['keyframes'][string],
	params: { position: string; name: string; id: string; ease: string }
) {
	return {
		position: params.position,
		event: params.position === '0' ? '' : params.name,
		eventId: params.position === '0' ? '' : params.id,
		props: Object.values(keyframe.props).reduce((acc, prop) => {
			acc[prop.name.toUpperCase()] = {
				...prop,
				name: prop.name.toUpperCase(),
				ease: prop.ease ? prop.ease : params.ease,
			};
			return acc;
		}, {}),
	};
}

/**
 * This migration function is designed to replace legacy css style properties.
 *
 * - `grid-column-gap` and `grid-row-gap` are now considered legacy properties, and the newer `column-gap` and `row-gap`
 *   properties are recommended for use in both CSS Grid and Flexbox.
 *   In addition, starting with `v1.57.0`, BOX_TYPE.STACK has been improved to become a flexbox.
 *
 * @since v1.57.0
 */
function replaceStyles(draftComponent: BBModel) {
	// Limit migration to the BOX|SORTABLE_BOX components only, as long as only these components require a style update
	if (draftComponent.type === COMPONENT_TYPE.BOX || draftComponent.type === COMPONENT_TYPE.SORTABLE_BOX) {
		const styles = (draftComponent.uiConfig.componentProps.styles as WithStateAndPlatform<BBStylesProp>) ?? {};

		Object.keys(styles).forEach(state => {
			const stateStyles = styles[state];
			Object.keys(stateStyles).forEach(platform => {
				const platformStyles = stateStyles[platform];

				if (platformStyles.gridRowGap) {
					platformStyles.rowGap = platformStyles.gridRowGap;
					delete platformStyles.gridRowGap;
				}

				if (platformStyles.gridColumnGap) {
					platformStyles.columnGap = platformStyles.gridColumnGap;
					delete platformStyles.gridColumnGap;
				}
			});
		});
	}
}
