import type { IReportSelectedStory } from 'types/report-stories';
import {
	LOGOUT,
	GET_BIG_PICTURE_STORY_REPORT,
	GET_BY_CARD_STORY_REPORT,
	GET_ORGANIZATION,
} from 'admin/constants/actions';
import type { GetReportStoryBigPictureResult, GetReportStoryByCardResult } from 'admin/actions/story/reports';
import { GetOrganization } from 'admin/actions/organization/organization';

type Payload<T> = WithRequired<IBackendBody<T>, 'result'>;

type ActionTypes =
	| { type: typeof GET_BIG_PICTURE_STORY_REPORT.FULFILLED; payload: Payload<GetReportStoryBigPictureResult> }
	| { type: typeof GET_BY_CARD_STORY_REPORT.FULFILLED; payload: Payload<GetReportStoryByCardResult> }
	| { type: typeof GET_BIG_PICTURE_STORY_REPORT.REJECTED; payload: string }
	| { type: typeof GET_BY_CARD_STORY_REPORT.REJECTED; payload: string }
	| { type: typeof LOGOUT.FULFILLED; payload: void }
	| { type: typeof GET_ORGANIZATION.FULFILLED; payload: Payload<GetOrganization['res']> };

type ReportState = {
	selectedStory: IReportSelectedStory;
	error: string;
};

const reportStoriesInitialState: ReportState = {
	selectedStory: {
		data: null,
		bigPicture: null,
		cardByCard: null,
	},
	error: '',
};

function reportsReducer(state = reportStoriesInitialState, { type, payload }: ActionTypes): ReportState {
	switch (type) {
		case GET_BIG_PICTURE_STORY_REPORT.FULFILLED:
			return {
				...state,
				error: '',
				selectedStory: {
					...state.selectedStory,
					data: payload.result.story,
					bigPicture: payload.result.analytics,
				},
			};
		case GET_BY_CARD_STORY_REPORT.FULFILLED:
			return {
				...state,
				error: '',
				selectedStory: {
					...state.selectedStory,
					data: payload.result.story,
					cardByCard: payload.result.analytics,
				},
			};

		case GET_BIG_PICTURE_STORY_REPORT.REJECTED:
			return {
				...state,
				error: payload,
				selectedStory: {
					...state.selectedStory,
					bigPicture: reportStoriesInitialState.selectedStory.bigPicture,
				},
			};

		case GET_BY_CARD_STORY_REPORT.REJECTED:
			return {
				...state,
				error: payload,
				selectedStory: {
					...state.selectedStory,
					cardByCard: reportStoriesInitialState.selectedStory.cardByCard,
				},
			};

		case LOGOUT.FULFILLED:
		case GET_ORGANIZATION.FULFILLED:
			return reportStoriesInitialState;

		default:
			return state;
	}
}

export default reportsReducer;
