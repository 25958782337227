/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export default ({ color = '#FFF', ...props }: SVGAttributes<SVGElement> & { color?: string }) => (
	<svg width="24px" {...props} viewBox="0 0 24 24">
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M20,4.04210526 L20,20.2105263 L19,20.2105263 L19,4.04210526 L20,4.04210526 Z M15.01,8.08421053 L19,12.1263158 L15.01,16.1684211 L15.01,13.1368421 L12,13.1368421 L12,11.1157895 L15.01,11.1157895 L15.01,8.08421053 Z M5,4.04210526 L5,12.1251053 L8.99,8.08421053 L8.99,11.1157895 L12,11.1157895 L12,13.1368421 L8.99,13.1368421 L8.99,16.1684211 L5,12.1271053 L5,20.2105263 L4,20.2105263 L4,4.04210526 L5,4.04210526 Z"
				fill={color}
			/>
		</g>
	</svg>
);
