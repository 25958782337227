import { createThunkActionType } from 'common/thunk-action';

// Auth
export const LOGIN = createThunkActionType('LOGIN');
export const GET_ME = createThunkActionType('GET_ME');
export const LOGOUT = createThunkActionType('LOGOUT');
export const POST_TOTP = createThunkActionType('POST_TOTP');
export const POST_TOTP_REQUIREMENT = createThunkActionType('POST_TOTP_REQUIREMENT');
export const POST_TOTP_VERIFY = createThunkActionType('POST_TOTP_VERIFY');
export const POST_TOTP_RECOVERY_CODES = createThunkActionType('POST_TOTP_RECOVERY_CODES');
export const POST_TOTP_REMOVE = createThunkActionType('POST_TOTP_REMOVE');
export const GET_TOTP = createThunkActionType('GET_TOTP');

// Init
export const INIT = createThunkActionType('INIT');

// User
export const SET_ACTIVE_TEAM = 'SET_ACTIVE_TEAM';

// Loading
export const SET_LOADING = 'SET_LOADING' as const;

// Popup
export const SET_MODAL = 'SET_MODAL';

// Report Story
export const GET_BY_CARD_CSV_URL = createThunkActionType('GET_BY_CARD_CSV_URL');

export const GET_BIG_PICTURE_STORY_REPORT = createThunkActionType('GET_BIG_PICTURE_STORY_REPORT');

export const GET_BY_CARD_STORY_REPORT = createThunkActionType('GET_BY_CARD_STORY_REPORT');

// Story
export const CREATE_STORY = createThunkActionType('CREATE_STORY');
export const GET_GENERATED_STORY_CONTENT = createThunkActionType('GET_GENERATED_STORY_CONTENT');
export const GET_GENERATED_CARD_CONTENT = createThunkActionType('GET_GENERATED_CARD_CONTENT');
export const DUPLICATE_STORY = createThunkActionType('DUPLICATE_STORY');
export const DELETE_STORY = createThunkActionType('DELETE_STORY');
export const UPDATE_LATEST_STORY = createThunkActionType('UPDATE_LATEST_STORY');
export const STORY_ROLLBACK = {
	START: '@STORY_ROLLBACK/START',
	SUCCESS: '@STORY_ROLLBACK/SUCCESS',
	FAIL: '@STORY_ROLLBACK/FAIL',
} as const;
export const UPDATE_STORY = {
	UPDATE: '@UPDATE_STORY/UPDATE',
	SUCCESS: '@UPDATE_STORY/SUCCESS',
	FAIL: '@UPDATE_STORY/FAIL',
} as const;
export const SET_DEFAULT_STORY_DOMAIN = {
	UPDATE: '@SET_DEFAULT_STORY_DOMAIN/UPDATE',
	SUCCESS: '@SET_DEFAULT_STORY_DOMAIN/SUCCESS',
	FAIL: '@SET_DEFAULT_STORY_DOMAIN/FAIL',
};

export const UPDATE_PUBLISHED_STORY = createThunkActionType('UPDATE_PUBLISHED_STORY');
export const UNPUBLISH_PUBLISHED_STORY = createThunkActionType('UNPUBLISH_PUBLISHED_STORY');
export const GET_STORY = createThunkActionType('GET_STORY');
export const GET_STORY_SPREADSHEET_INTEGRATIONS = createThunkActionType('GET_STORY_SPREADSHEET_INTEGRATIONS');
export const GET_STORY_DICTIONARY = createThunkActionType('GET_STORY_DICTIONARY');
export const GET_STORYCARDS_DOMAIN = createThunkActionType('GET_STORYCARDS_DOMAIN');
export const PUT_STORYCARDS_DOMAIN = createThunkActionType('PUT_STORYCARDS_DOMAIN');

export const UPDATE_STORY_URL = {
	UPDATE: '@UPDATE_STORY_URL/UPDATE',
	SUCCESS: '@UPDATE_STORY_URL/SUCCESS',
	FAIL: '@UPDATE_STORY_URL/FAIL',
};

export const UPDATE_EDITABLE_STORY = {
	UPDATE: '@UPDATE_EDITABLE_STORY/UPDATE',
} as const;

export const GET_STORY_AUDIENCE = createThunkActionType('GET_STORY_AUDIENCE');

// Videos
export const DELETE_VIDEO = createThunkActionType('DELETE_VIDEO');

// Member:
export const UPDATE_MEMBER_EMAIL = createThunkActionType('UPDATE_MEMBER_EMAIL');

export const VERIFY_MEMBER_EMAIL_RESEND = createThunkActionType('VERIFY_MEMBER_EMAIL_RESEND');

export const UPDATE_MEMBER_PASSWORD = createThunkActionType('UPDATE_MEMBER_PASSWORD');

export const GET_MEMBER = createThunkActionType('GET_MEMBER');

export const RESET_PASSWORD = createThunkActionType('RESET_PASSWORD');

export const RESTORE_PASSWORD = createThunkActionType('RESTORE_PASSWORD');

export const DELETE_MEMBER = createThunkActionType('DELETE_MEMBER');

export const UPDATE_MEMBER = createThunkActionType('UPDATE_MEMBER');

export const CREATE_MEMBER = createThunkActionType('CREATE_MEMBER');

export const UPDATE_MEMBER_AVATAR = createThunkActionType('UPDATE_MEMBER_AVATAR');

export const GET_MEMBER_PREFERENCES = createThunkActionType('GET_MEMBER_PREFERENCES');

export const SET_MEMBER_PREFERENCES = createThunkActionType('SET_MEMBER_PREFERENCES');

export const GET_FEATURE_FLAGS = createThunkActionType('GET_FEATURE_FLAGS');

// Organization:
export const GET_ORGANIZATION_LIST = createThunkActionType('GET_ORGANIZATION_LIST');

export const GET_ORGANIZATION = createThunkActionType('GET_ORGANIZATION');

export const ACCEPT_INVITE_TO_ORGANIZATION = createThunkActionType('ACCEPT_INVITE_TO_ORGANIZATION');

export const UPDATE_ORGANIZATION_MEMBER = createThunkActionType('UPDATE_ORGANIZATION_MEMBER');

export const REMOVE_MEMBER_FROM_ORGANIZATION = createThunkActionType('REMOVE_MEMBER_FROM_ORGANIZATION');

export const RESEND_INVITE_TO_ORGANIZATION = createThunkActionType('RESEND_INVITE_TO_ORGANIZATION');

export const SETUP_INVITE_TO_ORGANIZATION = createThunkActionType('SETUP_INVITE_TO_ORGANIZATION');

export const GET_ORGANIZATION_TEAMS_OF_MEMBER = createThunkActionType('GET_ORGANIZATION_TEAMS_OF_MEMBER');

export const GET_ORGANIZATION_ADMIN_BILLING_INFO = createThunkActionType('GET_ORGANIZATION_ADMIN_BILLING_INFO');

export const GET_ORGANIZATION_ADMIN_INVOICES_INFO = createThunkActionType('GET_ORGANIZATION_ADMIN_INVOICES_INFO');

export const UPDATE_ORGANIZATION_PAYMENT = createThunkActionType('UPDATE_ORGANIZATION_PAYMENT');

export const UPDATE_ORGANIZATION_BILLING = createThunkActionType('UPDATE_ORGANIZATION_BILLING');

export const GET_ORGANIZATION_MEMBERS = createThunkActionType('GET_ORGANIZATION_MEMBERS');

export const ADD_MEMBERS_TO_ORGANIZATION_TEAMS = createThunkActionType('ADD_MEMBERS_TO_ORGANIZATION_TEAMS');

export const VALIDATE_ORGANIZATION_NAME = createThunkActionType('VALIDATE_ORGANIZATION_NAME');

export const UPDATE_ORGANIZATION_SETTINGS = createThunkActionType('UPDATE_ORGANIZATION_SETTINGS');

export const CANCEL_SUBSCRIPTION = createThunkActionType('CANCEL_SUBSCRIPTION');

export const UPDATE_SUBSCRIPTION = createThunkActionType('UPDATE_SUBSCRIPTION');

export const GET_ORGANIZATION_DOMAINS = createThunkActionType('GET_ORGANIZATION_DOMAINS');

export const REMOVE_ORGANIZATION_DOMAIN = createThunkActionType('REMOVE_ORGANIZATION_DOMAIN');

export const RETRY_ORGANIZATION_DOMAIN_CONNECTION = createThunkActionType('RETRY_ORGANIZATION_DOMAIN_CONNECTION');

export const UPDATE_ORGANIZATION_DOMAIN = createThunkActionType('UPDATE_ORGANIZATION_DOMAIN');

export const SET_DEFAULT_ORGANIZATION_DOMAIN = createThunkActionType('SET_DEFAULT_ORGANIZATION_DOMAIN');

export const GET_ORGANIZATION_DOMAIN_STATUS = createThunkActionType('GET_ORGANIZATION_DOMAIN_STATUS');

export const GET_ORGANIZATION_STORIES = createThunkActionType('GET_ORGANIZATION_STORIES');

export const UPDATE_ORGANIZATION_HOMEPAGE = createThunkActionType('UPDATE_ORGANIZATION_HOMEPAGE');

// Team:
export const CREATE_TEAM = createThunkActionType('CREATE_TEAM');

export const UPDATE_TEAM = createThunkActionType('UPDATE_TEAM');

export const GET_TEAM_MEMBERS = createThunkActionType('GET_TEAM_MEMBERS');

export const UPDATE_TEAM_MEMBER = createThunkActionType('UPDATE_TEAM_MEMBER');

export const DELETE_TEAM_MEMBER = createThunkActionType('DELETE_TEAM_MEMBER');

export const DELETE_TEAM = createThunkActionType('DELETE_TEAM');

// Plans
export const GET_PLANS = createThunkActionType('GET_PLANS');

export const GET_PLAN_CHARGE_INFO = createThunkActionType('GET_PLAN_CHARGE_INFO');

// Templates
export const GET_CARD_TEMPLATES = createThunkActionType('GET_CARD_TEMPLATES');
export const GET_STORY_TEMPLATES = createThunkActionType('GET_STORY_TEMPLATES');

// Version
export const SET_VERSION = 'SET_VERSION';

// Card Editor
export const SET_EDITOR_MODE = 'SET_EDITOR_MODE';
export const SET_EDITABLE_EDITOR_ELEMENT = 'SET_EDITABLE_EDITOR_ELEMENT';
export const SET_TRAVERSE_TREE_COLLECTED_DATA = 'SET_TRAVERSE_TREE_COLLECTED_DATA';
export const SET_EDITABLE_ELEMENT_MODE = 'SET_EDITABLE_ELEMENT_MODE';
export const SET_EDITOR_CURRENT_MEDIA_QUERY = 'SET_EDITOR_CURRENT_MEDIA_QUERY';
export const SET_EDITABLE_STATE = 'SET_EDITABLE_STATE';
export const SET_EDITOR_SIZE = 'SET_EDITOR_SIZE';
export const UPDATE_EDITABLE_CARD_DATA = 'UPDATE_EDITABLE_CARD_DATA';
export const RESET_EDITABLE_CARD = 'RESET_EDITABLE_CARD';
export const CARD_EDITOR_UNMOUNT_LOADING = 'CARD_EDITOR_UNMOUNT_LOADING';
export const CARD_EDITOR_SYNC = {
	DRAFT: '@CARD_EDITOR_SYNC/DRAFT',
	SYNC: '@CARD_EDITOR_SYNC/SYNC',
	FAIL: '@CARD_EDITOR_SYNC/FAIL',
	SUCCESS: '@CARD_EDITOR_SYNC/SUCCESS',
} as const;
export const STORY_EDITOR_SYNC = {
	DRAFT: '@STORY_EDITOR_SYNC/DRAFT',
	SYNC: '@STORY_EDITOR_SYNC/SYNC',
	FAIL: '@STORY_EDITOR_SYNC/FAIL',
	SUCCESS: '@STORY_EDITOR_SYNC/SUCCESS',
} as const;
export const STORY_EDITOR_UNMOUNT = '@STORY_EDITOR/UNMOUNT';
export const CARD_EDITOR_UNMOUNT = '@CARD_EDITOR/UNMOUNT';
export const APPLY_STORY_VOLUNTARY_UPDATES = {
	START: '@APPLY_STORY_VOLUNTARY_UPDATES/START',
	SUCCESS: '@APPLY_STORY_VOLUNTARY_UPDATES/SUCCESS',
	FAIL: '@APPLY_STORY_VOLUNTARY_UPDATES/FAIL',
} as const;
