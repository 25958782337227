/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export default ({ color = '#FFF', ...props }: SVGAttributes<SVGElement> & { color?: string }) => (
	<svg width="24px" {...props} viewBox="0 0 24 24">
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M7.01,11 L4,11 L4,13 L7.01,13 L7.01,16 L11,12 L7.01,8 L7.01,11 Z M16.99,11 L20,11 L20,13 L16.99,13 L16.99,16 L13,12 L16.99,8 L16.99,11 Z M11.5,4 L12.5,4 L12.5,20 L11.5,20 L11.5,4 Z"
				fill={color}
			/>
		</g>
	</svg>
);
