/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export default ({ color = '#FFF', ...props }: SVGAttributes<SVGElement> & { color?: string }) => (
	<svg width="24px" {...props} viewBox="0 0 24 24">
		<path
			d="M19.71 8.04L7.75 20H4v-3.75L15.96 4.29a.994.994 0 01.646-.29h.118c.196.012.389.08.552.208l.094.083 2.34 2.34c.36.36.388.924.083 1.316l-.083.094zM14.5 5.758l3.75 3.75-.88.874-3.72-3.782.85-.842zM7.656 2.923a.405.405 0 01.302.307 2.853 2.853 0 002.098 2.158l.055.014a.467.467 0 01.006.904l-.176.046a2.754 2.754 0 00-1.983 2.034.406.406 0 01-.79.007 2.92 2.92 0 00-2.013-2.087l-.184-.053A.464.464 0 015 5.355l.02-.004a2.85 2.85 0 002.147-2.12.407.407 0 01.49-.308zM5.569 9.62c.15.685.677 1.226 1.358 1.396l.035.009a.302.302 0 01.004.585l-.114.03a1.782 1.782 0 00-1.283 1.316.263.263 0 01-.51.004l-.002-.004a1.887 1.887 0 00-1.302-1.346l-.118-.035a.3.3 0 01.018-.58l.013-.003A1.844 1.844 0 005.057 9.62v-.005a.262.262 0 01.512.005z"
			fill={color}
		/>
	</svg>
);
