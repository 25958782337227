import React, { HTMLAttributes } from 'react';
import cn from 'classnames';
import { isGradient } from 'common/utils/assets';
import { getColorAlpha, parseRgba, rgbToHex } from 'admin/utils/color-helpers';
import css from './FillPreview.scss';

interface Props extends Pick<HTMLAttributes<HTMLDivElement>, 'className' | 'onClick'> {
	value: string;
}

const FillPreview = ({ value = '', ...props }: Props) => {
	const isBackgroundImage = isGradient(value) || value.startsWith('url(') || value === 'none';
	const isEmptyValue = isBackgroundImage ? value === 'none' : getColorAlpha(value) === '0' ? true : !value;

	let title = value;
	if (!isBackgroundImage) {
		const { r, g, b } = parseRgba(value);
		title = `#${rgbToHex(r, g, b).toUpperCase()}`;
	}

	return (
		<div
			title={title}
			onClick={props.onClick}
			className={cn(css.preview, props.className, { [css.empty]: isEmptyValue })}
			style={{ background: isEmptyValue ? 'rgba(255, 255, 255, 1)' : value }}
		/>
	);
};

export default FillPreview;
