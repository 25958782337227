import _ from 'lodash';
import { appLog } from 'utils/helpers';
import translations from './translations.json';

const log = appLog.extend('utils:translate');

log('translations', translations);

type Locale = 'en' | 'he' | 'ru';
type Source = 'admin' | 'client';
/**
 * @class Translator
 * saves locale for translation context
 */
class Translator {
	locale: Locale;

	source: Source;

	constructor(locale: Locale = 'en', source: Source = 'admin') {
		this.locale = locale;
		this.source = source;
		log({ locale, source });
	}

	translate = (path: string, options?: any): string => {
		// log('Translator@translate locale', this.locale);
		const fullPath = `${this.locale}.${this.source}.${path}`;
		return translate(fullPath, options);
	};
}

/**
 * @desc template settings for translaton
 * @see {@link https://lodash.com/docs/4.17.10#templateSettings}
 */
const translateTemplateSettings = {
	interpolate: /{{([\s\S]+?)}}/g,
};

/**
 * translate - translate strings, optionally with parameters
 * @see {@link https://lodash.com/docs/4.17.10#template}
 *
 * @param  {string} path = '' path in translations object
 * @param  {object} [options]   template data (optional)
 * @return {string} parsed template
 */
function translate(path = '', options): string {
	// log('[translate] arguments', path, options);

	let result = _.get(translations, path, path);
	if (_.isObject(options)) {
		const compiled = _.template(result, translateTemplateSettings);
		result = compiled(options);
	}

	// log('[translate] result', result);

	return result;
}

export const translator = new Translator();
export default translator.translate;
