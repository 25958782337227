import { get, set, unset } from 'lodash';
import UrlHelper from 'utils/url-helper';
import { ADMIN_SESSION } from 'common/constants';
import { StoryMigrationAdapter } from 'admin/utils/story-migration-adapter';

const isV2ApiRegExp = new RegExp(`${UrlHelper.adminBackendV2}|${UrlHelper.adminBackendV2R}`, 'i');

/**
 * interceptor for api requests - adds session id and isBrowser keys
 *
 * @param {object} req - request object
 * @param {object} requestOptions - request options
 * @param {object} requestOptions.data - request data
 * @return req
 */
export function apiRequest(req, requestOptions) {
	req.withCredentials();

	set(requestOptions, ['data'], isV2ApiRegExp.test(req.url) ? requestOptions.data : { data: requestOptions.data });

	// Admin uses x-session-token. Client uses cookies
	// get existing session id when in browser
	const sessionId = localStorage.getItem(ADMIN_SESSION);

	if (sessionId) {
		set(requestOptions, ['headers', 'x-session-token'], sessionId);
	} else {
		unset(requestOptions, ['headers', 'x-session-token']);
	}

	return req;
}

/**
 * apiClientResponse - interceptor for api responses - sets session id in local storage
 *
 * @param {object} req - request object
 * @param {object} res - response object
 * @param {object} options - request options
 * @return res
 */
export function apiResponse(req, res, options) {
	// Admin uses x-session-token. Client uses cookies
	const sessionId = get(res, ['headers', 'x-session-token']);

	// store session id
	if (sessionId) {
		try {
			localStorage.setItem(ADMIN_SESSION, sessionId);
		} catch (e) {
			console.error('unable to set id');
		}
	}

	// Story migration adapter
	if (options.storyAdapter) {
		if (Array.isArray(res.body.result)) {
			res.body.result = res.body.result.map(story => {
				return new StoryMigrationAdapter({ story }).adapt();
			});
		} else if (res.body.result) {
			res.body.result = new StoryMigrationAdapter({ story: res.body.result }).adapt();
		}
	}

	if (req.url.endsWith('/api/dashboard/v2.0/rest/story/ra2rZwGzX/analytics/flow')) {
		set(res, 'body', flowStats());
	} else if (req.url.endsWith('/api/dashboard/story/ra2rZwGzX')) {
		set(res, 'body.result.storyVersions.published.settings.screenshot', screenshots());
	}

	return res;
}

function flowStats() {
	const multiplier = parseInt(new URL(window.location.href).searchParams.get('m') ?? '10_000', 10);
	return {
		'7d660344': {
			exits: 4 * multiplier,
			visits: 4 * multiplier,
			uniques: 4 * multiplier,
		},
		'95ac1e57': {
			exits: 19 * multiplier,
			visits: 73 * multiplier,
			uniques: 52 * multiplier,
			submissions: 54 * multiplier,
		},
		cc5eb977: {
			exits: 45 * multiplier,
			visits: 134 * multiplier,
			uniques: 75 * multiplier,
		},
		'4c85f487': {
			exits: 7 * multiplier,
			visits: 21 * multiplier,
			uniques: 20 * multiplier,
		},
		'3a888528': {
			exits: 17 * multiplier,
			visits: 54 * multiplier,
			uniques: 43 * multiplier,
			submissions: 38 * multiplier,
		},
		'8661791f': {
			exits: 11 * multiplier,
			visits: 37 * multiplier,
			uniques: 36 * multiplier,
			submissions: 27 * multiplier,
		},
		'1665967b': {
			exits: 5 * multiplier,
			visits: 26 * multiplier,
			uniques: 25 * multiplier,
			submissions: 21 * multiplier,
		},
		'7294d6a4': {
			exits: 16 * multiplier,
			visits: 89 * multiplier,
			uniques: 60 * multiplier,
			submissions: 74 * multiplier,
		},
		c58d41fe: {
			exits: 10 * multiplier,
			visits: 14 * multiplier,
			uniques: 14 * multiplier,
		},
	};
}

function screenshots() {
	return {
		'1665967b': {
			desktop: {
				hosting: 'prod-storycards-gallery-imgix',
				filepath: '2024/11/18/ae5549ea-b0c6-4a2b-aa3b-52b795758ebe.jpg',
			},
		},
		'3a888528': {
			desktop: {
				hosting: 'prod-storycards-gallery-imgix',
				filepath: '2024/11/18/f9360d4d-4b16-4f49-8132-eeef00a926e7.jpg',
			},
		},
		'4c85f487': {
			desktop: {
				hosting: 'prod-storycards-gallery-imgix',
				filepath: '2024/11/18/fb08db51-1f3e-463a-b58d-c3fbbdbb701d.jpg',
			},
		},
		'7294d6a4': {
			desktop: {
				hosting: 'prod-storycards-gallery-imgix',
				filepath: '2024/11/18/7f68dbe5-9010-4fd2-9deb-1ef19f4da04e.jpg',
			},
		},
		'7d660344': {
			desktop: {
				hosting: 'prod-storycards-gallery-imgix',
				filepath: '2024/11/18/e71e2eca-1f7e-4b03-be4f-fa42edafbd1a.jpg',
			},
		},
		'8661791f': {
			desktop: {
				hosting: 'prod-storycards-gallery-imgix',
				filepath: '2024/11/18/a8b4346e-d9f0-4faa-aa4f-ba156963a772.jpg',
			},
		},
		'95ac1e57': {
			desktop: {
				hosting: 'prod-storycards-gallery-imgix',
				filepath: '2024/11/18/887e0ed6-70f8-46c8-a952-f5ef03420f3a.jpg',
			},
		},
		c0af6fe7: {
			desktop: {
				hosting: 'prod-storycards-gallery-imgix',
				filepath: '2024/11/18/d8f12f40-516a-455a-8638-385ded4e1f96.jpg',
			},
		},
		c58d41fe: {
			desktop: {
				hosting: 'prod-storycards-gallery-imgix',
				filepath: '2024/11/18/21d23202-96c1-4094-b72d-357df5a6f1f5.jpg',
			},
		},
		cc5eb977: {
			desktop: {
				hosting: 'prod-storycards-gallery-imgix',
				filepath: '2024/11/18/710fe2c0-8a07-4a7c-b52d-448ae686b6ef.jpg',
			},
		},
		EhVA6VUdU: {
			desktop: {
				hosting: 'prod-storycards-gallery-imgix',
				filepath: '2024/11/18/a1a5b407-d438-43de-8e2a-08df2928dc2b.jpg',
			},
		},
	};
}
