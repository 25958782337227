import { orderBy, countBy } from 'lodash';
import React, { ReactNode, useMemo, useRef } from 'react';
import type { CardData, StorySymbols, StoryVersionType } from 'types/story';
import { rgbaRegExp } from 'common/utils/regexp';
import { getColorAlpha } from 'admin/utils/color-helpers';

import { useAdminSelector } from 'admin/reducers';
import { selectStoryCards } from 'admin/reducers/story-editor/selectors';
import { selectCardEditor } from 'admin/reducers/card-editor/selectors';
import { selectEditableElement } from 'admin/reducers/card-editor-extra/selectors';

import { useClientSelector } from 'client/reducers';
import { selectStoryFacade } from 'client/reducers/story/selectors';
import { selectEditableElementId } from 'client/reducers/editor/selectors';

type DataProviderProps = {
	children: (data: DataProvidedProps) => React.ReactNode;
};

type DataProvidedProps = {
	cards: CardData[];
	symbols: StorySymbols;
	storyElements: StoryVersionType['data']['elements'];
	editableElementId: string;
};

type ChildrenType = {
	children: (swatches: string[]) => ReactNode;
};

const AdminDataProvider: React.FC<DataProviderProps> = props => {
	const { _id: editableElementId } = useAdminSelector(selectEditableElement);
	const { symbols, storyElements, data: editableCard } = useAdminSelector(selectCardEditor);
	const storyCards = useAdminSelector(selectStoryCards);
	const cards = useMemo(
		() => [...storyCards.filter(card => card._id !== editableCard?._id), editableCard!],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[editableCard?._id]
	);

	return <>{props.children({ cards, symbols, storyElements, editableElementId })}</>;
};

const ClientDataProvider: React.FC<DataProviderProps> = props => {
	const editableElementId = useClientSelector(selectEditableElementId);
	const { cards, elements: storyElements, symbols } = useClientSelector(selectStoryFacade);

	return <>{props.children({ cards, symbols, storyElements, editableElementId })}</>;
};

const dataProvider = {
	admin: AdminDataProvider,
	client: ClientDataProvider,
};

const SwatchesProvider: React.FC<DataProvidedProps & ChildrenType> = props => {
	const swatch = useMemo(() => {
		const dataStr = JSON.stringify({
			cards: props.cards,
			symbols: props.symbols,
			storyElements: props.storyElements,
		});
		const colors = dataStr.match(rgbaRegExp);
		return orderBy(Object.entries(countBy(colors)), arr => arr[1], ['desc'])
			.map(arr => arr[0])
			.filter(color => getColorAlpha(color) !== '0')
			.slice(0, 8);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.editableElementId]);

	return <>{props.children(swatch)}</>;
};

const StorySwatches: React.FC<{ route: 'admin' | 'client' } & ChildrenType> = props => {
	const { current: DataProvider } = useRef(dataProvider[props.route]);

	return (
		<DataProvider>
			{data => (
				<SwatchesProvider {...data}>
					{swatches => {
						return props.children(swatches);
					}}
				</SwatchesProvider>
			)}
		</DataProvider>
	);
};

export default StorySwatches;
