import { isUndefined } from 'lodash';

export const hasValue = v => v !== '' && !isUndefined(v) && !Number.isNaN(v);

export const minMax = ({ value, min, max }: { value: number; min?: number; max?: number }) => {
	if (min !== undefined && value < min) {
		return min;
	}
	if (max !== undefined && value > max) {
		return max;
	}
	return value;
};
