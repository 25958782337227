/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export default ({ color = '#FFF', ...props }: SVGAttributes<SVGElement> & { color?: string }) => (
	<svg width="24px" {...props} viewBox="0 0 24 24">
		<path
			d="M5 6a1 1 0 00-1 1v3a1 1 0 102 0V7a1 1 0 00-1-1zm4.667 0a1 1 0 00-1 1v3a1 1 0 102 0V7a1 1 0 00-1-1zm0 7a1 1 0 00-1 1v3a1 1 0 002 0v-3a1 1 0 00-1-1zm4.666-7a1 1 0 00-1 1v3a1 1 0 102 0V7a1 1 0 00-1-1zm0 7a1 1 0 00-1 1v3a1 1 0 002 0v-3a1 1 0 00-1-1zM19 6a1 1 0 00-1 1v3a1 1 0 002 0V7a1 1 0 00-1-1z"
			fill={color}
		/>
	</svg>
);
