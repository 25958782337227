import React, { HTMLAttributes, SyntheticEvent } from 'react';
import cn from 'classnames';
import { Icon, IconType } from 'admin/components/common/Icon';
import css from './Button.scss';

type Props = {
	className?: string;
	children: React.ReactNode;
	onClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
	disabled?: boolean;
	stretch?: boolean;
	preset?: 'reset'; // ui preset
	resetIcon?: IconType;
} & HTMLAttributes<HTMLButtonElement>;

export const Button = React.forwardRef<HTMLButtonElement, Props>(
	({ children, className, onClick, stretch, preset, resetIcon = 'sc-rotate-left', ...props }, ref) => {
		return (
			<button
				type="button"
				{...props}
				className={cn(css.pfButton, className, { [css.stretch]: stretch, [css.reset]: preset === 'reset' })}
				onClick={onClick}
				ref={ref}
			>
				{preset === 'reset' && <Icon type={resetIcon} width={16} />}
				{children}
			</button>
		);
	}
);
