/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export default ({ color = '#FFF', ...props }: SVGAttributes<SVGElement> & { color?: string }) => (
	<svg width="68px" height="68px" {...props} viewBox="0 0 68 68">
		<path
			fill={color}
			fillRule="evenodd"
			d="M57.674 2.554l.18.095c3.097 1.657 5.556 4.055 7.277 7.097l.22.4C67.079 13.378 68 16.584 68 25.426v17.149c0 8.677-.887 11.926-2.554 15.1l-.095.179c-1.657 3.097-4.055 5.556-7.097 7.277l-.4.22C54.622 67.079 51.416 68 42.574 68H25.426c-8.677 0-11.926-.887-15.1-2.554l-.179-.095a18.021 18.021 0 01-7.497-7.497l-.192-.367C.883 54.432.033 51.21 0 43.06L0 25.425c0-8.677.887-11.926 2.554-15.1l.095-.179a18.021 18.021 0 017.497-7.497l.367-.192C13.568.883 16.79.033 24.94 0L42.575 0c8.677 0 11.926.887 15.1 2.554zM42.282 35.717a2 2 0 00-2.806.354l-8.31 10.707-5.493-6.614a2 2 0 00-3.117.05L14.166 51h39.667L42.656 36.097a2 2 0 00-.374-.38z"
		/>
	</svg>
);
