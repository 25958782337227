import { SET_VERSION } from 'admin/constants/actions';
import { AdminReducerState } from 'admin/reducers';

type VersionState = {
	default: string;
	current: string;
};

const versionInitialState: VersionState = {
	default: 'latest',
	current: 'latest',
};

type LoadingActions = { type: typeof SET_VERSION; payload: string };

function versionReducer(state = versionInitialState, action: LoadingActions): VersionState {
	if (action.type === SET_VERSION) {
		return {
			...state,
			current: action.payload,
		};
	}
	return state;
}

export const selectCurrentVersion = (state: AdminReducerState) => state.version.current;

export default versionReducer;
