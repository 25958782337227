import produce from 'immer';
import * as actionType from 'admin/constants/actions';
import { STORIES_FILTER_DEFAULT } from 'admin/constants/common';
import type * as MT from 'admin/actions/member';
import type * as TT from 'admin/actions/organization/team';
import type * as OT from 'admin/actions/organization/organization';
import type { GetMeResult } from 'admin/actions/get-me';
import type { LogoutAction } from 'admin/actions/logout';
import type { IUser, MemberPreferences } from 'src/types/user';
import type { DeleteStoryResult } from 'admin/actions/story/delete-story';
import type { CreateStoryResult } from 'admin/actions/story/create-story';
import type { DuplicateStoryResult } from 'admin/actions/story/duplicate-story';
import type { GetTOTPResult, PostTOTPRequirementResult } from 'admin/actions/auth';
import type { SetDefaultOrganizationDomainResult } from 'admin/actions/organization/set-default-organization-domain';
import type { IOrganizationMember, IOrganizationTeamMember, OrganizationDomains } from 'src/types/organization';
import type { GetOrganizationDomainStatusAction } from 'admin/actions/organization/get-organization-domain-status';

const PRIVATE_TEAM_ID = '';

const userInitialState: IUser = {
	id: '',
	member: {
		id: '',
		email: '',
		name: '',
		country: '',
		lastLogin: '',
		createdAt: '',
	},
	roles: [],
	organizations: [],
	paymentPlan: '',
	preferences: {
		editor: {},
		last: {},
		storiesFilter: STORIES_FILTER_DEFAULT,
		storiesView: 'grid',
		videosView: 'grid',
	},
	isInitialized: false,
	verified: false,
	features: {},
	auth: {
		totp: {
			enforcement: {
				required: false,
				enabled: false,
			},
			enabled: false,
			phone: null,
			totp: {
				verified: false,
				recoveryCodes: [],
				type: 'app',
				url: '',
			},
		},
	},
	activeTeam: PRIVATE_TEAM_ID,
	organization: null,
	organizationAuxData: {
		activeMembers: [],
		activeTeamMembers: [],
		activeMember: null,
		domains: [],
	},
};

type Payload<T> = WithRequired<IBackendBody<T>, 'result'>;
type Action<T, P, IsPayloadWrapped extends boolean = true> = {
	type: T;
	payload: IsPayloadWrapped extends true ? Payload<P> : P;
};

type ActionTypes =
	| LogoutAction
	| GetOrganizationDomainStatusAction
	| Action<typeof actionType.INIT.FULFILLED, void, false>
	| Action<typeof actionType.SET_ACTIVE_TEAM, string, false>
	| Action<typeof actionType.GET_FEATURE_FLAGS.FULFILLED, MT.GetFeatureFlagsResult>
	| Action<typeof actionType.UPDATE_MEMBER_EMAIL.FULFILLED, MT.UpdateMemberEmailResult>
	| Action<typeof actionType.UPDATE_MEMBER_AVATAR.FULFILLED, MT.UpdateMemberAvatarResult>
	| Action<typeof actionType.UPDATE_MEMBER.FULFILLED, MT.UpdateMemberResult>
	| Action<typeof actionType.GET_ME.FULFILLED, GetMeResult>
	| Action<typeof actionType.SET_DEFAULT_ORGANIZATION_DOMAIN.FULFILLED, SetDefaultOrganizationDomainResult>
	| Action<typeof actionType.CREATE_STORY.FULFILLED, CreateStoryResult>
	| Action<typeof actionType.DUPLICATE_STORY.FULFILLED, DuplicateStoryResult>
	| Action<typeof actionType.DELETE_STORY.FULFILLED, DeleteStoryResult>
	| Action<typeof actionType.CREATE_TEAM.FULFILLED, TT.CreateTeamResult>
	| Action<typeof actionType.UPDATE_TEAM.FULFILLED, TT.UpdateTeamResult>
	| Action<typeof actionType.DELETE_TEAM.FULFILLED, TT.DeleteTeamResult>
	| Action<typeof actionType.ADD_MEMBERS_TO_ORGANIZATION_TEAMS.FULFILLED, void>
	| Action<typeof actionType.REMOVE_MEMBER_FROM_ORGANIZATION.FULFILLED, OT.RemoveMemberFromOrganizationResult>
	| Action<typeof actionType.GET_MEMBER_PREFERENCES.FULFILLED, MemberPreferences>
	| Action<typeof actionType.SET_MEMBER_PREFERENCES.FULFILLED, MemberPreferences>
	| Action<typeof actionType.CANCEL_SUBSCRIPTION.FULFILLED, OT.CancelSubscriptionResult>
	| Action<typeof actionType.UPDATE_ORGANIZATION_SETTINGS.FULFILLED, OT.UpdateOrganizationSettingsResult>
	| Action<typeof actionType.GET_MEMBER.FULFILLED, MT.GetMemberResult>
	| Action<typeof actionType.GET_ORGANIZATION_LIST.FULFILLED, OT.GetOrganizationListResult>
	| Action<typeof actionType.GET_ORGANIZATION.FULFILLED, OT.GetOrganization['res']>
	| Action<typeof actionType.GET_TOTP.FULFILLED, GetTOTPResult>
	| Action<typeof actionType.POST_TOTP_VERIFY.FULFILLED, void, false>
	| Action<typeof actionType.POST_TOTP_REQUIREMENT.FULFILLED, PostTOTPRequirementResult>
	| Action<typeof actionType.GET_ORGANIZATION_MEMBERS.FULFILLED, OT.GetOrganizationMembersResult>
	| Action<typeof actionType.REMOVE_MEMBER_FROM_ORGANIZATION.FULFILLED, OT.RemoveMemberFromOrganizationResult>
	| Action<typeof actionType.GET_TEAM_MEMBERS.FULFILLED, TT.GetTeamMembersResult>
	| Action<typeof actionType.GET_ORGANIZATION_TEAMS_OF_MEMBER.PENDING, undefined, false>
	| Action<typeof actionType.GET_ORGANIZATION_TEAMS_OF_MEMBER.FULFILLED, OT.GetOrganizationTeamsOfMemberResult>
	| Action<typeof actionType.UPDATE_ORGANIZATION_MEMBER.FULFILLED, OT.UpdateOrganizationMemberResult>
	| Action<typeof actionType.DELETE_TEAM_MEMBER.FULFILLED, TT.DeleteTeamMemberResult>
	| Action<typeof actionType.UPDATE_TEAM_MEMBER.FULFILLED, TT.UpdateTeamMemberResult>
	| Action<typeof actionType.GET_ORGANIZATION_DOMAINS.FULFILLED, OrganizationDomains>;

function user(state = userInitialState, { type, payload }: ActionTypes): IUser {
	switch (type) {
		case actionType.GET_ME.FULFILLED:
			return {
				...state,
				...payload.result,
			};
		case actionType.GET_ORGANIZATION_LIST.FULFILLED:
			return {
				...state,
				organizations: payload.result,
			};
		case actionType.GET_MEMBER.FULFILLED:
			return {
				...state,
				...payload.result,
			};
		case actionType.UPDATE_MEMBER.FULFILLED:
			return {
				...state,
				member: { ...state.member, ...payload.result },
			};
		case actionType.UPDATE_MEMBER_AVATAR.FULFILLED:
			return {
				...state,
				member: {
					...state.member,
					profilePictureFile: { ...state.member.profilePictureFile, ...payload.result },
				},
			};
		case actionType.UPDATE_MEMBER_EMAIL.FULFILLED:
			return payload.result.memberId === state.id
				? {
						...state,
						member: { ...state.member, ...payload.result.data },
					}
				: state;
		case actionType.SET_ACTIVE_TEAM:
			return {
				...state,
				activeTeam: payload,
			};
		case actionType.GET_ORGANIZATION.FULFILLED:
			return {
				...state,
				organization: {
					...payload.result,
					...(payload.result.favicon && { favicon: `${payload.result.favicon}?ts=${Date.now()}` }),
				},
			};
		case actionType.LOGOUT.FULFILLED:
			return userInitialState;
		case actionType.INIT.FULFILLED:
			return {
				...state,
				isInitialized: true,
			};
		case actionType.GET_MEMBER_PREFERENCES.FULFILLED:
		case actionType.SET_MEMBER_PREFERENCES.FULFILLED:
			return {
				...state,
				preferences: { ...state.preferences, ...payload.result },
			};
		case actionType.UPDATE_ORGANIZATION_SETTINGS.FULFILLED: {
			return {
				...state,
				organization: produce(state.organization!, draft => {
					draft.name = payload.result.data.name;
					draft.metadata = {
						...draft.metadata,
						...payload.result.data.metadata,
					};
				}),
			};
		}
		case actionType.CANCEL_SUBSCRIPTION.FULFILLED: {
			return {
				...state,
				organization: produce(state.organization!, draft => {
					draft.subscriptionStatus = 'CANCEL_ON_RENEWAL';
				}),
			};
		}
		case actionType.REMOVE_MEMBER_FROM_ORGANIZATION.FULFILLED:
			return {
				...state,
				organization: produce(state.organization!, draft => {
					draft.seatsCount = Math.max(0, (draft.seatsCount ?? 0) - 1);
				}),
				organizationAuxData: produce(state.organizationAuxData, draft => {
					draft.activeMembers = removeMemberById(draft.activeMembers, payload.result.memberId);
					draft.activeTeamMembers = removeMemberById(draft.activeTeamMembers, payload.result.memberId);
				}),
			};
		case actionType.ADD_MEMBERS_TO_ORGANIZATION_TEAMS.FULFILLED:
			return {
				...state,
				organization: produce(state.organization!, draft => {
					draft.seatsCount = (draft.seatsCount ?? 0) + 1;
				}),
			};
		case actionType.CREATE_TEAM.FULFILLED:
			return {
				...state,
				organization: produce(state.organization!, draft => {
					draft.teamsCount = (draft.teamsCount ?? 0) + 1;
				}),
			};
		case actionType.DELETE_TEAM.FULFILLED:
			return {
				...state,
				organization: produce(state.organization!, draft => {
					draft.teamsCount = Math.max(0, (draft.teamsCount ?? 0) - 1);
					draft.teams = draft.teams.filter(team => team.id !== payload.result.teamId) as typeof draft.teams;
				}),
			};
		case actionType.UPDATE_TEAM.FULFILLED:
			return {
				...state,
				organization: produce(state.organization!, draft => {
					draft.teams.forEach((team, index) => {
						if (team.id === payload.result.teamId) {
							draft.teams[index].name = payload.result.data.name;
						}
					});
				}),
			};
		case actionType.DELETE_STORY.FULFILLED:
			return {
				...state,
				organization: produce(state.organization!, draft => {
					draft.storiesCount = Math.max(0, (draft.storiesCount ?? 0) - 1);
				}),
			};
		case actionType.DUPLICATE_STORY.FULFILLED:
		case actionType.CREATE_STORY.FULFILLED:
			return {
				...state,
				organization: produce(state.organization!, draft => {
					draft.storiesCount = (draft.storiesCount ?? 0) + 1;
				}),
			};
		case actionType.SET_DEFAULT_ORGANIZATION_DOMAIN.FULFILLED:
			return produce(state, draft => {
				draft.organization!.domainId = payload.result.domainId;
			});
		case actionType.GET_FEATURE_FLAGS.FULFILLED:
			return { ...state, features: payload.result };
		case actionType.GET_TOTP.FULFILLED:
			return produce(state, draft => {
				draft.auth.totp = { enforcement: draft.auth.totp.enforcement, ...payload.result };
			});
		case actionType.POST_TOTP_REQUIREMENT.FULFILLED:
			return produce(state, draft => {
				draft.auth.totp.enforcement = payload.result;
				draft.auth.totp.enabled = payload.success && payload.result.enabled;
			});
		case actionType.POST_TOTP_VERIFY.FULFILLED:
			return produce(state, draft => {
				draft.auth.totp.totp.verified = true;
			});
		case actionType.GET_ORGANIZATION_MEMBERS.FULFILLED:
			return produce(state, draft => {
				draft.organizationAuxData.activeMembers = payload.result;
			});
		case actionType.GET_TEAM_MEMBERS.FULFILLED:
			return produce(state, draft => {
				draft.organizationAuxData.activeTeamMembers = payload.result;
			});
		case actionType.GET_ORGANIZATION_TEAMS_OF_MEMBER.PENDING:
			return produce(state, draft => {
				draft.organizationAuxData.activeMember = null;
			});
		case actionType.GET_ORGANIZATION_TEAMS_OF_MEMBER.FULFILLED:
			return produce(state, draft => {
				draft.organizationAuxData.activeMember = payload.result;
			});
		case actionType.UPDATE_ORGANIZATION_MEMBER.FULFILLED:
			return {
				...state,
				organizationAuxData: produce(state.organizationAuxData, draft => {
					draft.activeMembers = draft.activeMembers.map(member =>
						member.memberId === payload.result.memberId ? { ...member, ...payload.result.data } : member
					);
					draft.activeMember =
						draft.activeMember?.id === payload.result.memberId
							? { ...draft.activeMember, ...payload.result.data }
							: draft.activeMember;
				}),
			};
		case actionType.DELETE_TEAM_MEMBER.FULFILLED: {
			const { teamId, memberId } = payload.result;
			return {
				...state,
				organizationAuxData: produce(state.organizationAuxData, draft => {
					if (draft.activeMember) {
						draft.activeMember.teams = draft.activeMember.teams.filter(team => team.id !== teamId);
					}
					draft.activeTeamMembers = draft.activeTeamMembers.filter(member => {
						return !(member.teamId === teamId && member.memberId === memberId);
					});
				}),
			};
		}
		case actionType.UPDATE_TEAM_MEMBER.FULFILLED: {
			return produce(state, draft => {
				draft.organizationAuxData.activeMember?.teams.forEach(draftTeam => {
					if (draftTeam.id === payload.result.teamId) {
						draftTeam.teamMembers = { ...draftTeam.teamMembers, ...payload.result.data };
					}
				});
			});
		}
		case actionType.GET_ORGANIZATION_DOMAINS.FULFILLED:
			if (payload.success) {
				return produce(state, draft => {
					draft.organizationAuxData.domains = payload.result;
				});
			}
			return state;
		case actionType.GET_ORGANIZATION_DOMAIN_STATUS.FULFILLED:
			if (payload.success) {
				return produce(state, draft => {
					draft.organizationAuxData.domains = draft.organizationAuxData.domains.map(domain => ({
						...domain,
						status: domain.id === payload.result.id ? payload.result.status : domain.status,
						message: domain.id === payload.result.id ? payload.result.statusMessage : domain.message,
					}));
				});
			}
			return state;
		default:
			return state;
	}
}

function removeMemberById<T extends IOrganizationMember | IOrganizationTeamMember>(members: T[], memberId: string) {
	const index = members.findIndex((member: T) => member.memberId === memberId);
	if (index >= 0) {
		return [...members.slice(0, index), ...members.slice(index + 1)];
	}
	return [...members];
}
export default user;
