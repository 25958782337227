/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export default ({ color = '#FFF', ...props }: SVGAttributes<SVGElement> & { color?: string }) => (
	<svg width="24px" {...props} viewBox="0 0 24 24">
		<polygon
			fill={color}
			points="24 13.571 13.714 13.571 13.714 23 10.286 23 10.286 13.571 0 13.571 0 10.429 10.286 10.429 10.286 1 13.714 1 13.714 10.429 24 10.429"
		/>
	</svg>
);
