import produce from 'immer';
import { pickBy, identity, size } from 'lodash';

import type { StoryModel, StorySettingsType, StoryVersionType } from 'types/story';
import { getOrganizationStories as getOrganizationStoriesEndpoint } from 'admin/resources';
import { GET_ORGANIZATION_STORIES } from 'admin/constants/actions';
import { STORIES_OFFSET } from 'admin/constants/common';
import { createThunkAction } from 'admin/actions/helpers';

export enum StoriesScope {
	teamStories = 'teamStories',
	orgStories = 'orgStories',
}

export type Params = {
	organizationId?: string;
};

export type Query = {
	sort?: string;
	term?: string;
	createdBy?: string;
	teamId?: string;
	status?: string;
	offset?: number;
};

type ResponseStoryVersion = Omit<StoryVersionType, 'storyId' | 'data' | 'settings'> &
	Record<'settings', Pick<StorySettingsType, 'screenshot'>>;

export type ResponseStory = Pick<
	StoryModel,
	| 'clientStoryId'
	| 'createdAt'
	| 'id'
	| 'language'
	| 'member'
	| 'name'
	| 'organizationId'
	| 'status'
	| 'tags'
	| 'teamId'
	| 'type'
	| 'updatedAt'
> & {
	views: number;
	leads: number;
	completionRate: number;
	latest: ResponseStoryVersion;
	published?: ResponseStoryVersion;
};

export type GetOrganizationStoriesResult = {
	teamStories?: ResponseStory[];
	orgStories?: ResponseStory[];
	push: boolean;
};

export const getOrganizationStories = createThunkAction<
	GetOrganizationStoriesResult,
	{ params: Params; query: Query; scope: StoriesScope.teamStories | StoriesScope.orgStories }
>({
	type: GET_ORGANIZATION_STORIES,
	payloadCreator: async ({ params, query, scope }, { getState }) => {
		const { user } = getState();
		const { storiesFilter } = user.preferences;

		const _query: Query = {
			offset: STORIES_OFFSET,
			status: storiesFilter.status,
			sort: storiesFilter.orderBy,
			createdBy: storiesFilter.createdBy,
			...query,
		};

		if (_query.createdBy) {
			if (_query.createdBy === 'me') {
				_query.createdBy = user.id;
			} else {
				delete _query.createdBy;
			}
		}

		// API scalars don't support int 0:
		if (_query.offset === 0) delete _query.offset;

		// if we need 'all' stories — just skip this param:
		if (_query.status === 'all') delete _query.status;

		const _params: Params = {
			organizationId: user.organization?.id,
			...params,
		};

		const commonReq = {
			params: _params,
			query: pickBy(_query, identity) as Query,
		};

		const req = {
			team: produce(commonReq, draft => {
				// if teamId is not provided — it is 'private' filter, if 'private' -> 'createdBy' = user.id
				if (!draft.query.teamId) {
					draft.query.teamId = 'null'; // idk, it is important for 'private' filter on BE..R
					draft.query.createdBy = user.id;
				}
			}),
			org: produce(commonReq, draft => {
				delete draft.query.teamId;
			}),
		};

		const shouldFetchTeamAndOrg = size(commonReq.query.term) > 0 && !commonReq.query.offset;
		const shouldFetchOrgStories = scope === StoriesScope.orgStories || shouldFetchTeamAndOrg;
		const shouldFetchTeamStories = scope === StoriesScope.teamStories || shouldFetchTeamAndOrg;

		interface ResponseType extends Omit<Response, 'body'> {
			body: IBackendBody<{ data: ResponseStory[] }> | null;
		}

		const [{ body: teamStoriesResp }, { body: orgStoriesResp }]: ResponseType[] = await Promise.all([
			shouldFetchTeamStories
				? getOrganizationStoriesEndpoint.params(req.team.params).send(req.team.query)
				: Promise.resolve({ body: null }),
			shouldFetchOrgStories
				? getOrganizationStoriesEndpoint.params(req.org.params).send(req.org.query)
				: Promise.resolve({ body: null }),
		]);

		const result: GetOrganizationStoriesResult = {
			push: !!commonReq.query.offset,
			teamStories: teamStoriesResp?.success && teamStoriesResp.result?.data ? teamStoriesResp.result.data : [],
			orgStories: orgStoriesResp?.success && orgStoriesResp.result?.data ? orgStoriesResp.result.data : [],
		};

		return {
			...teamStoriesResp,
			...orgStoriesResp,
			success: true,
			result,
		};
	},
});
