import _ from 'lodash';
import produce from 'immer';
import { DEFAULT_EDITABLE_STATE_INFO } from 'common/constants';
import undoable, { combineFilters } from 'redux-undo';
import type { CardEditorType } from 'types/story';
import type { Action, Reducer } from 'redux';
import type { StateSyncAction } from 'admin/middleware/state-sync-middleware';
import {
	RESET_EDITABLE_CARD,
	SET_EDITABLE_STATE,
	SET_EDITOR_CURRENT_MEDIA_QUERY,
	UPDATE_EDITABLE_CARD_DATA,
} from 'admin/constants/actions';

type CardEditorActionType = Action<
	| typeof SET_EDITABLE_STATE
	| typeof SET_EDITOR_CURRENT_MEDIA_QUERY
	| typeof UPDATE_EDITABLE_CARD_DATA
	| typeof RESET_EDITABLE_CARD
> & { payload?: any };

type ActionType = Partial<StateSyncAction<CardEditorActionType>> & CardEditorActionType;

export const initialState: CardEditorType = {
	storyElements: [],
	storySettings: {},
	symbols: {},
	currentMediaQuery: 'default',
	data: null, // editable card object
	cardPath: '', // path to card in state.storyEditor object
	state: DEFAULT_EDITABLE_STATE_INFO,
	stateSyncAt: null,
};

function cardEditorReducer(state = initialState, { type, payload }: ActionType) {
	if (type === SET_EDITABLE_STATE) {
		return {
			...state,
			state: produce(state.state, draft => {
				_.set(draft, 'state', _.get(payload, 'state', initialState.state.state));
				_.set(draft, 'source.id', _.get(payload, 'id', initialState.state.source.id));
				_.set(draft, 'source.type', _.get(payload, 'type', initialState.state.source.type));
				_.set(draft, 'source.path', _.get(payload, 'path', initialState.state.source.path));
				_.set(draft, 'source.layer', _.get(payload, 'layer', initialState.state.source.layer));
				_.set(draft, 'source.states', _.get(payload, 'states', initialState.state.source.states));
			}),
		};
	}

	if (type === SET_EDITOR_CURRENT_MEDIA_QUERY) {
		return {
			...state,
			currentMediaQuery: payload,
		};
	}

	if (type === UPDATE_EDITABLE_CARD_DATA) {
		const { path, value, data, storySettings, storyElements, symbols, cardPath = state.cardPath } = payload;

		// accurate updates
		if (_.isArray(path) && _.isArray(value)) {
			return produce(state, draft => {
				path.forEach((p, i) => {
					if (
						p.startsWith('storyElements') ||
						p.startsWith('storySettings') ||
						p.startsWith('data') ||
						p.startsWith('symbols')
					) {
						_.set(draft, p, value[i]);
					}
				});
			});
		}

		// update entire key
		return {
			...state,
			cardPath,
			data: data || state.data,
			storyElements: storyElements || state.storyElements,
			storySettings: storySettings || state.storySettings,
			symbols: symbols || state.symbols,
		};
	}

	if (type === RESET_EDITABLE_CARD) {
		return initialState;
	}

	return state;
}

// assign last synced date
function synced(reducer: typeof cardEditorReducer): Reducer<CardEditorType, ActionType> {
	return (state, action) => {
		if (state === undefined) {
			return initialState;
		}

		const nextState = reducer(state, action);

		if (action.__stateSync && action.type === UPDATE_EDITABLE_CARD_DATA) {
			return {
				...nextState,
				stateSyncAt: new Date().toISOString(),
			};
		}

		return nextState;
	};
}

const syncedCardEditorReducer = synced(cardEditorReducer);

function hasMediaQuery(action, state, history) {
	const cond1 = state.currentMediaQuery !== initialState.currentMediaQuery;
	const cond2 = history.present.currentMediaQuery !== initialState.currentMediaQuery;
	return cond1 && cond2;
}

function undoableAction(action) {
	const undoableActions = [UPDATE_EDITABLE_CARD_DATA, SET_EDITOR_CURRENT_MEDIA_QUERY, SET_EDITABLE_STATE];
	return undoableActions.includes(action.type);
}

function checkEditableState(action, state, history) {
	if (action.type === SET_EDITABLE_STATE) {
		// allow only if dispatched state different from present
		return action.payload !== history.present.state;
	}
	return true;
}

const undoableCardEditorReducer = undoable(syncedCardEditorReducer, {
	filter: combineFilters(undoableAction, hasMediaQuery, checkEditableState),
	limit: 20,
	ignoreInitialState: true,
	syncFilter: true,
});

export default undoableCardEditorReducer;
