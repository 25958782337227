import { DEFAULT_MEDIA_QUERY_PLATFORMS, DEFAULT_MEDIA_QUERY_SIZES } from 'common/constants';

const { FULL_HD, DESKTOP, TABLET, MOBILE, MOBILE_LANDSCAPE } = DEFAULT_MEDIA_QUERY_PLATFORMS;

const platformProperties = {
	[FULL_HD]: {
		minWidth: DEFAULT_MEDIA_QUERY_SIZES.fullHD.min,
		maxWidth: null,
		uiName: FULL_HD,
	},
	[DESKTOP]: {
		minWidth: DEFAULT_MEDIA_QUERY_SIZES.desktop.min,
		maxWidth: null,
		uiName: DESKTOP,
	},
	[TABLET]: {
		minWidth: DEFAULT_MEDIA_QUERY_SIZES.tablet.min,
		maxWidth: DEFAULT_MEDIA_QUERY_SIZES.desktop.min - 1,
		uiName: TABLET,
	},
	[MOBILE_LANDSCAPE]: {
		minWidth: DEFAULT_MEDIA_QUERY_SIZES.mobileLandscape.min,
		maxWidth: DEFAULT_MEDIA_QUERY_SIZES.tablet.min - 1,
		uiName: MOBILE_LANDSCAPE,
	},
	[MOBILE]: {
		minWidth: DEFAULT_MEDIA_QUERY_SIZES.mobile.min,
		maxWidth: DEFAULT_MEDIA_QUERY_SIZES.mobileLandscape.min - 1,
		uiName: MOBILE,
	},
};

export const platformsConfig = {
	[FULL_HD]: {
		[FULL_HD]: {
			...platformProperties[FULL_HD],
			minWidth: null,
			maxWidth: null,
		},
		[DESKTOP]: {
			...platformProperties[DESKTOP],
			minWidth: null,
			maxWidth: platformProperties[FULL_HD].minWidth - 1,
		},
		[TABLET]: {
			...platformProperties[TABLET],
			minWidth: null,
		},
		[MOBILE_LANDSCAPE]: {
			...platformProperties[MOBILE_LANDSCAPE],
			minWidth: null,
		},
		[MOBILE]: platformProperties[MOBILE],
	},
	[DESKTOP]: {
		[FULL_HD]: platformProperties[FULL_HD],
		[DESKTOP]: {
			...platformProperties[DESKTOP],
			minWidth: null,
			maxWidth: null,
		},
		[TABLET]: {
			...platformProperties[TABLET],
			minWidth: null,
		},
		[MOBILE_LANDSCAPE]: {
			...platformProperties[MOBILE_LANDSCAPE],
			minWidth: null,
		},
		[MOBILE]: platformProperties[MOBILE],
	},
	[TABLET]: {
		[FULL_HD]: platformProperties[FULL_HD],
		[DESKTOP]: {
			...platformProperties[DESKTOP],
			maxWidth: null,
		},
		[TABLET]: {
			...platformProperties[TABLET],
			minWidth: null,
			maxWidth: null,
		},
		[MOBILE_LANDSCAPE]: {
			...platformProperties[MOBILE_LANDSCAPE],
			minWidth: null,
		},
		[MOBILE]: platformProperties[MOBILE],
	},
	[MOBILE_LANDSCAPE]: {
		[FULL_HD]: platformProperties[FULL_HD],
		[DESKTOP]: {
			...platformProperties[DESKTOP],
			maxWidth: null,
		},
		[TABLET]: {
			...platformProperties[TABLET],
			maxWidth: null,
		},
		[MOBILE_LANDSCAPE]: {
			...platformProperties[MOBILE_LANDSCAPE],
			minWidth: null,
			maxWidth: null,
		},
		[MOBILE]: platformProperties[MOBILE],
	},
	[MOBILE]: {
		[FULL_HD]: platformProperties[FULL_HD],
		[DESKTOP]: {
			...platformProperties[DESKTOP],
			maxWidth: null,
		},
		[TABLET]: {
			...platformProperties[TABLET],
			maxWidth: null,
		},
		[MOBILE_LANDSCAPE]: {
			...platformProperties[MOBILE_LANDSCAPE],
			minWidth: null,
		},
		[MOBILE]: {
			...platformProperties[MOBILE],
			minWidth: null,
			maxWidth: null,
		},
	},
};
