import type { AsyncThunkActionReturnType } from 'types/redux-utils';
import { deleteStory } from 'admin/actions/story/delete-story';
import { LOGOUT, GET_ORGANIZATION_STORIES, DELETE_STORY, UPDATE_STORY } from 'admin/constants/actions';
import {
	getOrganizationStories,
	ResponseStory,
	StoriesScope,
} from 'admin/actions/organization/get-organization-stories';

const formatStory = ({ published, latest, ...storyData }: ResponseStory) => ({
	...storyData,
	storyVersions: {
		latest: { ...latest, storyId: storyData.id },
		...(published ? { published: { ...published, storyId: storyData.id } } : {}),
	},
});

const filterStoriesById = (stories: StoriesListItem[], excludeId: string) => stories.filter(s => s.id !== excludeId);

export type StoriesListItem = ReturnType<typeof formatStory>;

interface IStoriesReducer {
	[StoriesScope.teamStories]: StoriesListItem[];
	[StoriesScope.orgStories]: StoriesListItem[];
}

const storiesInitialState: IStoriesReducer = {
	[StoriesScope.teamStories]: [],
	[StoriesScope.orgStories]: [],
};

type ActionTypes =
	| {
			type: typeof GET_ORGANIZATION_STORIES.FULFILLED;
			payload: AsyncThunkActionReturnType<typeof getOrganizationStories>;
	  }
	| { type: typeof DELETE_STORY.FULFILLED; payload: AsyncThunkActionReturnType<typeof deleteStory> }
	| { type: typeof UPDATE_STORY.SUCCESS; payload: IBackendBody<{ storyId: string; newTeamId?: string }> }
	| { type: typeof LOGOUT.FULFILLED; payload: void }
	| { type: typeof GET_ORGANIZATION_STORIES.REJECTED; payload: void };

function storiesReducer(state = storiesInitialState, { type, payload }: ActionTypes): IStoriesReducer {
	switch (type) {
		case GET_ORGANIZATION_STORIES.FULFILLED: {
			if (payload.result) {
				const teamStories = payload.result.teamStories?.map(formatStory) ?? [];
				const orgStories = payload.result.orgStories?.map(formatStory) ?? [];
				return {
					...state,
					teamStories: payload.result.push ? [...state.teamStories, ...teamStories] : teamStories,
					orgStories: payload.result.push ? [...state.orgStories, ...orgStories] : orgStories,
				};
			}

			return state;
		}

		case DELETE_STORY.FULFILLED:
		case UPDATE_STORY.SUCCESS:
			if (type === UPDATE_STORY.SUCCESS && payload.result?.newTeamId === undefined) {
				return state;
			}

			if (payload.result?.storyId) {
				return {
					...state,
					teamStories: filterStoriesById(state.teamStories, payload.result.storyId),
					orgStories: filterStoriesById(state.orgStories, payload.result.storyId),
				};
			}

			return state;

		case LOGOUT.FULFILLED:
		case GET_ORGANIZATION_STORIES.REJECTED:
			return storiesInitialState;

		default:
			return state;
	}
}

export default storiesReducer;
